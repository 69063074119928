import React, {useState, useEffect} from 'react';
import './Carousel.css';
import { Url } from '../global';
import {Carousel} from 'antd'

import{LeftCircleFilled}
from '@ant-design/icons';

export const CarouselAntd = (props) => {
    const [state, setState] = useState(null);

   useEffect(async() => {
        // const response = await fetch('http://rsgrahahermine.id/android/get_banner.php');
        const response = await fetch( Url + '/android/get_banner.php');
        const json = await response.json();
        setState( prevData =>({ ...prevData, data: json}) );
   },[])
    
   console.log(state)

   if(state === null) return <></>

   const contentStyle = {
    margin: 0,
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
  };

  const SampleNextArrow = props => {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{ ...style,  background: 'red' }}
        onClick={onClick}
      />
    )
  }
  
  const SamplePrevArrow = props => {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{ ...style, background: 'green' }}
        onClick={onClick}
      >
       <LeftCircleFilled />
      </div>
    )
  }
  
  const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  }
  

   return(
            <div className="CarouselAntdContainer">
                <Carousel autoplay={true} autoplaySpeed={3000} arrows {...settings}>
            {
            //     state.data.map((n,index) =>  
            //         {
            //             const path = 'assets/banner/' + n.banner
                        
            //             return(
            //                 <div key={index}>
            //                     <div 
            //                             // className= {className}
            //                             style={{
            //                                     backgroundImage: `url(${ path })`, 
            //                                     backgroundRepeat: 'no-repeat', 
            //                                     backgroundSize: 'cover',
            //                                     width: '1280px',
            //                                     height: '500px'
            //                                 }}
                                                
            //                             >
            //                     </div>
            //                 </div>
          
            //             );
            //         }
            //     )
            }

                    <div>
                        <h3 style={contentStyle}>1</h3>
                    </div>
                    <div>
                        <h3 style={contentStyle}>2</h3>
                    </div>
                    <div>
                        <h3 style={contentStyle}>3</h3>
                    </div>
                    <div>
                        <h3 style={contentStyle}>4</h3>
                    </div>
                </Carousel>
            </div>
    );
}
       
export default CarouselAntd