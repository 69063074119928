import React, {useState, useEffect, useCallback} from 'react';
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import { Url } from '../global';

//COMPONENT
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import VideoViewer from "../page/VideoViewer";

//STYLE & ICON
import './Profile.css';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { ReactComponent as PlayWatermark } from "../assets/play.svg";
import { ReactComponent as PlayWatermarkActive } from "../assets/playActive.svg";


export const Profile = (props)=>{
const [data, setData] = React.useState([]);


useEffect(async() => {
  // const requestOptions = {
  //   method: 'POST',
  //   headers: { 'Content-Type': 'application/json' },
  //   body: JSON.stringify({ 'articleId': articleId })
  // };

  const response = await fetch(Url + '/android/get_profile.php');
  const json = await response.json();
  setData( json );

},[])

const DirectorPath = "assets/Direktur.jpg";
const DirectorImage = require("../" + DirectorPath);

const VisionPath = "assets/Vision.jpg";
const VisionImage = require("../" + VisionPath);

const MissionPath = "assets/Mission.jpg";
const MissionImage = require("../" + MissionPath);

return(
  <div className="ProfileContainer">
                          <div className="AboutContainer" >
                            <div className="LeftBox" style={{ backgroundImage: "url(" + DirectorImage + ")"  }}>
                            </div>

                            <div className="RightBox">
                                  <h1>Sambutan Direktur</h1>
                                  <p>
                                  Salam Sejahtera Bagi Kita Semua, Puji dan syukur senantiasa kita haturkan kehadirat Tuhan Yang Maha Kuasa, yang telah memberikan rahmat dan kasihnya kepada kita semua. 
                                  </p>
                                  
                                  <p>
                                  Rumah Sakit Graha Hermine merupakan Rumah Sakit Swasta dibawah naungan PT. Batam Sehat Madani, yang bertujuan untuk menyelenggakan pelayanan kesehatan dengan upaya penyembuhan, pemulihan, peningkatan, pencegahan, pelayanan, rujukan dan pengembangan serta pengabdian masyakarat.
                                  </p>
                                  
                                  <p>
                                  Rumah Sakit Graha Hermine berdiri pada tanggal 3 Desember 2009 telah memberikan kontribusi yang nyata dalam pelayanan kesehatan bagi masyarakat Batam. Berawal dari Rumah Bersalin lalu berkembang menjadi sebuah Rumah Sakit Umum dengan tipe kelas “C” berdasarkan penetapan dari Pemerintah melalui SK Wali Kota Batam Nomor: KPTS.00001/HK/III/2020 Tentang Pemberian Izin Operasional Tetap Rumah Sakit Umum Graha Hermine.
                                  </p>
                                  
                                  <p>
                                  Ucapan terimakasih kami sampaikan kepada karyawan Rumah Sakit Graha Hermine yang telah memberikan dedikasi, dan kerja keras kepada Rumah Sakit Graha Hermine untuk mewujudkan Visi Rumah Sakit Graha Hermine yaitu menjadi Rumah Sakit utama masyarakat kota Batam. Serta mewujudkan Misi Rumah Sakit Graha Hermine yaitu memberikan pelayanan kesehatan yang cepat, tepat, berkualitas dan mengutamakan kenyamanan serta keselamatan pasien.
                                  </p>
                                  
                                  <p>
                                  Seluruh Keluarga Besar Rumah Sakit Graha Hermine juga mengucapkan terimakasih kepada masyarakat yang telah memberikan kepercayaan kepada Rumah Sakit Graha Hermine, sehingga Rumah Sakit ini dapat tumbuh dan berkembang menjadi salah satu Rumah Sakit pilihan masyarakat di kota Batam khususnya Batu Aji dan sekitarnya. Saran dan kritik juga sangat kami butuhkan guna terus meningkatkan kualitas pelayanan kami kedepan. Wassallamu’allaikum warahmatullahi wabarakatuh
                                  </p>
                                  
                                  <p>
                                  Dr. Fajri Israq, S.H., MARS.
                                  </p>

                              </div>
                          </div>
                  
                          <div className="VisionContainer" >
                            <div className="LeftBox" style={{ backgroundImage: "url(" + VisionImage + ")"  }}>
                            </div>

                            <div className="RightBox">
                                <h1>Visi</h1>
                                <p>Menjadi Rumah Sakit pilihan utama masyarakat Batam tahun 2022</p>
                            </div>
                          </div>

                          <div className="MissionContainer" >
                            <div className="LeftBox" style={{ backgroundImage: "url(" + MissionImage + ")"  }}>
                            </div>

                            <div className="RightBox">
                                <h1>Misi</h1>
                                <p>Memberikan pelayanan kesehatan yang cepat, tepat dan berkualitas.</p>

                                <p>Mengutamakan kenyamanan dan keselamatan pasien.</p>

                                <p>Memberikan pelayanan kesehatan yang terjangkau dan kesejahteraan oleh seluruh lapisan masyarakat.</p>

                                <p>Meningkatkan pengetahuan, keterampilan karyawan, sehingga mampu melaksanakan pelayanan yang profesional.</p>

                                <p>Meningkatkan kualitas alat kedokteran yang dapat memberikan nilai lebih bagi pelayanan kesehatan. </p>

                            </div>
                          </div> 

        


  </div>
      
);

// THIS IS DYNAMIC FROM DB BUT CANT PROCESS TO LONG STRING CAUSE NOTHING SHOWING
// return(
//     <div className="ProfileContainer" >
        
//           {
//             data.map((item, index)=>{
//                     if(item.Section == "About"){
//                       const path = "assets/" + item.Photo;
//                       const image = require("../" + path);
//                       return(
//                             <div key={index} className="AboutContainer" >
//                               <div className="LeftBox" style={{ backgroundImage: "url(" + image + ")"  }}>
//                               </div>

//                               <div className="RightBox">
//                                   <h1>{item.Header}</h1>
//                                   <p>
//                                       {/* <img src={image}  height = "220" style={{float: "left", margin: "2em"}} /> */}
//                                       {item.Content}
//                                   </p>
//                               </div>
//                             </div>
//                       );
//                     }
                    
//                     if(item.Section == "Vision"){
//                       const path = "assets/" + item.Photo;
//                       const image = require("../" + path);
//                       return(
//                             <div key={index} className="VisionContainer" >
//                               <div className="LeftBox" style={{ backgroundImage: "url(" + image + ")"  }}>
//                               </div>

//                               <div className="RightBox">
//                                   <h1>{item.Header}</h1>
//                                   <p>{item.Content}</p>
//                               </div>
//                             </div>
//                      );
//                     }

//                     if(item.Section == "Mission"){
//                       const path = "assets/" + item.Photo;
//                       const image = require("../" + path);
//                       return(
//                             <div key={index} className="MissionContainer" >
//                               <div className="LeftBox" style={{ backgroundImage: "url(" + image + ")"  }}>
//                               </div>

//                               <div className="RightBox">
//                                   <h1>{item.Header}</h1>
//                                   <p>{item.Content}</p>
//                               </div>
//                             </div>
//                      );
//                     }
//               })

//           }
          


//     </div>
        
// );

}


export default Profile;

