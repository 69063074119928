import React from 'react';

function User(prop){
    return <h1> welcome back </h1>
}

function Guest(prop){
    return <h1> please sign up </h1>
}

function Greeting(prop){
    const isLoggedIn = prop.isLoggedIn;
    if(isLoggedIn){
        return <User />;
    }else{
        return <Guest />;
    }
}

function LoginButton(props){
    return(
        <button onClick={props.onClick}>
            Login
        </button>
    );
}

function LogoutButton(props){
    return(
        <button onClick={props.onClick}>
            Logout
        </button>
    );
}

class LoginControl extends React.Component{
    constructor(props){
        super(props);
        this.handleLoginClick = this.handleLoginClick.bind(this);
        this.handleLogoutClick = this.handleLogoutClick.bind(this);
        this.state = {isLoggedIn: false};
    }    

    handleLoginClick(){
        this.setState({isLoggedIn: true});
    }

    handleLogoutClick(){
        this.setState({isLoggedIn: false});
    }

    render(){
        const isLoggedIn = this.state.isLoggedIn;
        let button;

        if(isLoggedIn){
            button = <LogoutButton onClick={this.handleLogoutClick} />;
        }else{
            button = <LoginButton onClick={this.handleLoginClick} />;
        }

        // return(
        //     <div>
        //     the user is <b>{isLoggedIn ? 'currently' : 'not'}</b> logged in.
        //         <Greeting isLoggedIn={isLoggedIn}/>
        //         {button}
        //     </div>
        // );

        return (
            <div>
                the user is <b>{isLoggedIn ? 'currently' : 'not'}</b> logged in.
                {
                    isLoggedIn
                    ? <LogoutButton onClick={this.handleLogoutClick} />
                    : <LoginButton onClick={this.handleLoginClick} />
                }
            </div>
        );
    }
}

class Mailbox extends React.Component{
    constructor(props){
        super(props);
        this.state = props.unreadMessages;
    }

    // render(){
    //     const unreadMessages = this.state.unreadMessages;
    //     return(
    //         <div>
    //             <h1>Hello!</h1>
    //             {   //if true the element right after && will appear in output
    //                 unreadMessages.length > 0 &&
    //                 <h2>
    //                     You have {unreadMessages.length} unread messages.
    //                 </h2>
    //             }
    //         </div>
    //     );
    // }
   
    render(){
        const count = 0
        return(
            <div>
               {count && <h1>Message: {count} </h1>}
            </div>
        );
    }



}

function WarningBanner (props){
    if(!props.warn){
        return null;
    }

    return(
        <div className="warning">
            warning! 
        </div>
    );
}

class Page extends React.Component{
    constructor(props){
        super(props);
        this.state = {showWarning: true};
        this.handleToggleClick = this.handleToggleClick.bind(this);        
    }

    handleToggleClick(){
        this.setState(a => ({showWarning: !a.showWarning})
        );
    }    

    render(){
        return (
            <div>
                <WarningBanner warn={this.state.showWarning}/>
                <button onClick={this.handleToggleClick}>
                    {this.state.showWarning ? 'Hide' : 'Show'}
                </button>
            </div>
        );
    }
}

export default Page;
