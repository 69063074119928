import React, {useState, useEffect} from 'react';
import './Carousel.css';
import { Url } from '../global';

export const Carousel = (props) => {
    const [state, setState] = useState({clickId : 0, bannerLink: [], dataIsLoaded: false});

    const handleCarouselClick = (id) =>
    {
        setState( prevData =>({ ...prevData, clickId: id})  );
    }

   useEffect(async() => {
        // const response = await fetch('http://rsgrahahermine.id/android/get_banner.php');
        const response = await fetch( Url + '/android/get_banner.php');
        const json = await response.json();
        setState( prevData =>({ ...prevData, bannerLink: json}) );
   },[])

   let offset;
   let initialX;
   let target;

   const onMouseMove = (event) =>
   {
        offset = event.clientX - initialX;
        if(offset >= 100 && parseInt(target.id) > 0){
        // alert(offset)

            // alert('prev');
            let prev = parseInt(target.id) - 1;
            handleCarouselClick(prev);
        }

        else if(offset <= -100 && (parseInt(target.id) < state.bannerLink.length - 1)){
        // alert( ' current :' + event.clientX + ' initial :' + initialX + ' offset :' + offset)

            // alert('next');
            let next = parseInt(target.id) + 1;

            handleCarouselClick(next);

            // alert(next);
        }



    }

    const handleMouseDown = (event) =>
    {
        // alert(clickId);
        // target card
        target = event.target;
        // move position

        // initial mouse click
        initialX = event.clientX;
        document.onmousemove = onMouseMove;
        document.onmouseup = onMouseUp;


    }

    const onMouseUp = (event) => {
        //clear the listening handler
        document.onmousemove = null;
        document.onmouseup = null;
    }

        const bannerlink = state.bannerLink;
        const clickId = state.clickId;

        const width = 1280; //banner width
        let position = 0;
        position -= width * clickId;
        //#region POSITION LOGIC
        // set up left position if click slide index is 0 then left is 0
        // if click index is 1 then the left slide index 1 is 0
        // left:(width * index) <= is first setup postitioni,  + position <= adjusted position by click id
        //#endregion

        return(
            <React.Fragment>
                <div className="CarouselContainer">
                {
                    state.bannerLink.map((n,index) =>
                        {
                            const path = 'assets/banner/' + n.banner
                            // const image = require("../" + path); // <-- this is for build from source

                            let className = clickId == index ? 'flip' : 'flip';
                            return(
                                <React.Fragment key = {index}>
                                <div    id = {index}
                                        className= {className}
                                        style={{ left:(width * index) + position ,
                                                backgroundImage: `url(${ path })`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}
                                        onMouseDown={handleMouseDown} onMouseUp={onMouseUp}
                                        >
                                </div>
                                </React.Fragment>
                            );
                        }
                    )

                }
                </div>
                <div className='slideButton'>
                    <SlideButtonGroup
                        clickId={clickId} //parent state clickid pass to child constructor
                        slideQty={bannerlink} onCarouselButtonClick={handleCarouselClick}
                        />
                </div>
            </React.Fragment>
        );
}

export default Carousel

class SlideButtonGroup extends React.Component{
    constructor(props){
        super(props);
        //this.state = {clickId : -1}
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e){
        this.props.onCarouselButtonClick(e.target.id);
        //this.setState({clickId : e.target.id});
    }

    render(){
        const id = this.props.clickId;
        const slideQty = this.props.slideQty;
        const buttonGroup = slideQty.map(
            (buttonLabel, index) =>
                ( <div id={index} key={index}
                   onClick={this.handleClick}
                   className={ id == index ? "slide_button_clicked" : ""}>
                  </div> )
        );

        return(
            <React.Fragment>
            {buttonGroup}
            </React.Fragment>
        );
    }
}

