import React from 'react';
import Logo_GH from '../assets/logoText.png';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';

import Box from '@mui/material/Box';
import Menu  from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem'
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import MenuIcon from '@mui/icons-material/Menu';
import LoginIcon from '@mui/icons-material/Medication';
import '../index.css';



const theme1 = { backgroundColor: '#ffffff' };

const ResponsiveNavBar = () => {
  const pages = ['Home', 'Temu Dokter', 'Tentang RGH', 'Layanan', 'Informai', 'Hubungi Kami', 'Karir']
  const [menuState, SetMenuState] = React.useState(null);
  const [appointmentState, SetAppointmentState] = React.useState(false);
  const [aboutState, SetAboutState] = React.useState(null);
  const [serviceState, SetServiceState] = React.useState(null);
  const [contactState, SetContactState] = React.useState(null);
  const [informationState, SetInformationState] = React.useState(null);

  const handleOpenMenu = (event) =>{ SetMenuState(event.currentTarget);}
  const handleOpenAppointment = (event) =>{ SetAppointmentState(event.currentTarget);}
  const handleOpenAbout = (event) =>{ SetAboutState(event.currentTarget);}
  const handleOpenService = (event) =>{ SetServiceState(event.currentTarget);}
  const handleOpenContact = (event) =>{ SetContactState(event.currentTarget);}
  const handleOpenInformation = (event) =>{ SetInformationState(event.currentTarget);}


  const handleCloseMenu = (event) =>{ SetMenuState(null);}
  const handleCloseAppointment = (event) =>{ SetAppointmentState(false);}
  const handleCloseAbout = (event) =>{ SetAboutState(null);}
  const handleCloseService = (event) =>{ SetServiceState(null);}
  const handleCloseContact = (event) =>{ SetContactState(null);}
  const handleCloseInformation = (event) =>{ SetInformationState(null);}
      
  return (
      <AppBar position="static" style={theme1}>
      <Container>
          <div className='navTop'>
            <span> Cutomer Care (0778) 363 318 </span> 
            <span> Emergency (0778) 363 127  </span> 
          </div>
     
          <Toolbar>
            <img src={Logo_GH} width="auto" height="40" />
            <Box sx={{
                   flexGrow: 1, 
                   display:{ xs: 'none', md: 'flex', justifyContent: 'flex-end' }
                  }}> 
            {
                // pages.map((page, index) => (
                //   <Button key={page} sx = {{mx: 1, display: 'block'}} >
                //     {page}
                //   </Button>              
                // ))
            }

                  <Button key='Home' sx = {{mx: 1, display: 'block'}} > Home</Button>
                      
                      
                    <Button key='Appointment' sx = {{mx: 1, display: 'block'}} 
                      onMouseEnter={handleOpenAppointment}  
               
                    
                     >Temu Dokter</Button>              
                    <Menu id='AppointmentMenu'
                      anchorEl={appointmentState}
                      anchorOrigin={{vertical:'bottom', horizontal:'left'}}  
                      onClose={handleCloseAppointment}
                      open={Boolean(appointmentState)}
                      sx={{
                          display:{ xs: 'none', md: 'block' , fontSize: 0.8}
                        }}
                    >
                      <MenuItem>Jadwal Dokter</MenuItem>
                      <MenuItem>Pendaftaran Online</MenuItem>
                    </Menu>

    

                  <Button key='About' sx = {{mx: 1, display: 'block'}} onClick={handleOpenAbout}>Tentang RGH</Button>              
                  <Menu 
                    anchorEl={aboutState}
                    anchorOrigin={{vertical:'bottom', horizontal:'left'}}  
                    open={Boolean(aboutState)}
                    onClose={handleCloseAbout}
                    sx={{
                        display:{ xs: 'none', md: 'block' }
                      }}
                  >
                    <MenuItem>Profil</MenuItem>
                    <MenuItem>Mitra Kami</MenuItem>
                    <MenuItem>Penghargaan</MenuItem>
                  </Menu>

                  <Button key='Service' sx = {{mx: 1, display: 'block'}} onClick={handleOpenService}>Layanan</Button>              
                  <Menu 
                    anchorEl={serviceState}
                    anchorOrigin={{vertical:'bottom', horizontal:'left'}}  
                    open={Boolean(serviceState)}
                    onClose={handleCloseService}
                    sx={{
                        display:{ xs: 'none', md: 'block' }
                      }}
                  >
                    <MenuItem>Poliklinik</MenuItem>
                    <MenuItem>Intalai Gawat Darurat(IGD)</MenuItem>
                    <MenuItem>Penghargaan</MenuItem>
                    <MenuItem>Rawat Inap</MenuItem>
                    <MenuItem>Ibu Anak</MenuItem>
                    <MenuItem>Unggulan</MenuItem>
                  </Menu>

                  <Button key='Informasi' sx = {{mx: 1, display: 'block'}} onClick={handleOpenInformation}>Informai</Button>              
                  <Menu 
                    anchorEl={informationState}
                    anchorOrigin={{vertical:'bottom', horizontal:'left'}}  
                    open={Boolean(informationState)}
                    onClose={handleCloseInformation}
                    sx={{
                        display:{ xs: 'none', md: 'block' }
                      }}
                  >
                    <MenuItem>Promosi</MenuItem>
                    <MenuItem>Berita dan Artikel</MenuItem>
                    <MenuItem>Video Keehatan</MenuItem>
                  </Menu>
      
                  <Button key='HubungiKami' sx = {{mx: 1, display: 'block'}} onClick={handleOpenContact}>HubungiKami</Button>              
                  <Menu 
                    anchorEl={contactState}
                    anchorOrigin={{vertical:'bottom', horizontal:'left'}}  
                    open={Boolean(contactState)}
                    onClose={handleCloseContact}
                    sx={{
                        display:{ xs: 'none', md: 'block' }
                      }}
                  >
                    <MenuItem>Kontak</MenuItem>
                    <MenuItem>FAQ</MenuItem>
                    <MenuItem>Layanan IGD</MenuItem>
                    <MenuItem>Kuiioner Layanan</MenuItem>
                  </Menu>

                  <Button key='Karir' sx = {{mx: 1, display: 'block'}} >Karir</Button>              
                 
            </Box>
         
            <Box sx={{
                  //  flexGrow: 1, 
                   display:{ xs: 'flex', md: 'none' }
                  }}> 
              <IconButton 
              aria-label="menu"
              onClick={handleOpenMenu}
              >
                  <MenuIcon/>
              </IconButton>
              <Menu 
                anchorEl={menuState}
                // anchorOrigin={{vertical:'bottom', horizontal:'left'}}  
                open={Boolean(menuState)}
                onClose={handleCloseMenu}
                sx={{
                    display:{ xs: 'block', md: 'none' }
                  }}
              >
                <MenuItem>
                  item 1
                </MenuItem>
                
                <MenuItem> 
                  item 1
                </MenuItem>

                  item 1
                <MenuItem> 
                </MenuItem>
              </Menu>
            </Box>

        </Toolbar>
      </Container>
     
      </AppBar>


     

  );
}

export default ResponsiveNavBar
