import React, {useState, useEffect, useCallback} from 'react';
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import { Url } from '../global';

//COMPONENT
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PartnerViewer from "../page/PartnerViewer";

//STYLE & ICON
import './Partner.css';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';


export const Partner = (props)=>{
   const [currentIndex, setCurrentIndex] = React.useState(0);
   const [openPartner, setOpenPartner] = useState(false);

   const [data, setData] = useState([]);
   const [partnerId, setPartnerId] = useState(1);
   const [articleContent, setArticleContent] = useState([]);
   const [articlePhoto, setArticlePhoto] = useState([]);
   const [partnerHover, setPartnerHover] = useState([]);
   
   
   useEffect(async() => {
    const response = await fetch(Url + '/android/get_partner.php');
    const json = await response.json();
     setData( json );
     setPartnerHover(()=> data.map(() => false));

   },[])

//    useEffect(async() => {
//     // alert(articleId);
//     const requestOptions = {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify({ 'articleId': articleId })
//     };

//     const response1 = await fetch('https://127.0.0.1/grahahermine/android/get_article_content.php', requestOptions);
//     const json1 = await response1.json();
//     setArticleContent( json1[0] );

//     const response2 = await fetch('https://127.0.0.1/grahahermine/android/get_article_photo.php', requestOptions);
//     const json2 = await response2.json();
//     setArticlePhoto(json2);
    
//    },[articleId])



   const width = 520; //banner width
   let position = 0;
   position -= width * currentIndex;

   let itemCount = data.length;


const handleOver = (event) => {
  const newVisibilities = [...partnerHover];
  newVisibilities[event.currentTarget.id] = true;
  setPartnerHover(newVisibilities);
}

const handleOut = (event) => {
  const newVisibilities = [...partnerHover]; // store hover state to temp variable
  newVisibilities[event.currentTarget.id] = false; // set target id status in temp variablee
  setPartnerHover(newVisibilities); // set to videoHover state 
}

const PartnerViewer_Open = (event)=>{
    setPartnerId(event.currentTarget.id);
    setOpenPartner(true);
}

const PartnerViewer_Close = ()=>{
    setOpenPartner(false);
}

// alert(JSON.stringify(partnerHover));

    return(
            <div className="PartnerContainer">
            {
                data.map(
                    (item,index)=>{
                        if(item.Id == partnerId){
                        return(
                            <PartnerViewer 
                                open={openPartner}
                                PartnerViewer_Close={PartnerViewer_Close}
                                Photo={item.Photo}
                                Header={item.Header}
                                Content={item.Content}
                                />
                            )
                        }
                    }

                )

            }
            <h3>Mitra Kami :</h3>

                <div className="ControlContainer">
                    <div className="PartnerBox">
                    {

                        data.map((item, index)=>{
                            return(
                                    <InformationBox key={index} width = {width} index = {index} position = {position}
                                    partnerId = {item.Id}
                                    photo={item.Thumbnail} 
                                    Header = {item.Header}
                                    PartnerViewer_Open = {PartnerViewer_Open} 
                                    handleOver={handleOver}
                                    handleOut={handleOut}
                                    hover={partnerHover[item.Id]}
                                    />
                            );
                        })
                    }
                    </div>
                </div>

            </div>

        );
        // {videoHover[item.Id] ? <PlayWatermarkActive className="PlayWatermark" onClick={InformationViewer_Open}/> : <PlayWatermark className="PlayWatermark" onClick={InformationViewer_Open}/>}
}

const InformationBox = (props) =>
{
    const path = props.hover ? "assets/partner/" + props.photo : "assets/partner/inactive/" + props.photo ;
    const image = require("../" + path);
    let classname = props.hover ? "PartnerCard Active" : "PartnerCard" ;
    return (
                <Card className={classname}
                    id={props.partnerId} 
                >
                    <div id= {props.partnerId} style={{width:"250px", height: "250px", backgroundImage: `url(${ image })`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "80%"}}
                        onClick={props.PartnerViewer_Open}
                        onMouseOver={props.handleOver}
                        onMouseOut={props.handleOut}
                        >
                    </div>
                    <CardContent className="Content" id={props.partnerId} onClick={props.PartnerViewer_Open}>
                        {/* <h3>{props.Header}</h3>
                        <p className="Datetime">{props.CreateDate} </p>
                        <p>{props.Description}</p> */}
                    </CardContent>
                </Card>
    )


}


export default Partner;

