import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

//FORM INPUT
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';


//MODAL 
import Modal from '@mui/material/Modal';

//ICON
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import CloseIcon from '@mui/icons-material/Close';


const PartnerViewer = (props) => {
    const [currentIndex, setCurrentIndex ]= useState(0);
    const [data, setData ]= useState();
    const navigate = useNavigate();

    const path = "assets/partner/" + props.Photo;
    const image = require("../" + path);

    return(
        <Modal
          open={props.open}
          onClose={props.PartnerViewer_Close}  
          aria-labelledby="Partner Viewer"
          aria-describedby="Partner Viewer"
          className="ModalPartnerStyle"
        >
  
          <div className="ModalPartnerContainer">
              <div className = "TitleBar">
                  <IconButton aria-label="close" style={{borderRadius:"0"}} onClick={props.PartnerViewer_Close}>
                      <CloseIcon />    
                  </IconButton>
              </div>

              <div className="ControlContainer">
                  <div className="PartnerPhotoCarouselContainer">
                          <div className='PartnerPhoto'
                              style={{backgroundImage: `url(${ image })`, backgroundRepeat: 'no-repeat', backgroundSize: '60%', backgroundPosition:'center' }}>
                          </div>
                  </div>
              </div>

              <div className="ModalContent">
                  <h3>{props.Header}</h3>
                  <p className="ContentParagraph">{props.Content}</p>
              </div>

              
              
  
          </div>
        </Modal>
      );
   
  }
  
  
  export default PartnerViewer;
  