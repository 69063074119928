import React from 'react';
import './Carousel.css';

class Carousel extends React.Component{
    constructor(props)
    {
        super(props);
        this.handleCarouselClick = this.handleCarouselClick.bind(this);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);

        this.state = {clickId : 0, bannerLink: [], dataIsLoaded: false};
    }

    handleCarouselClick(id)
    {
        this.setState({clickId : id});
    }

    componentWillMount()
    {
        //fetch("http://localhost:2000/api/grahahermine/getbanner") node
        fetch("http://rsgrahahermine.id/android/get_banner.php")
        .then((res) => res.json())
        .then((json)=> { this.setState({bannerLink: json}); });
    }

    slideNext(id){
        this.setState({clickId : id});
    }

    onMouseMove(event, offset, initialX){
        offset = event.clientX - initialX;
        // target.style.left = offset + 'px';
        alert(event.clientX + ' ' + initialX );
        if(offset >= 100){
            let next = parseInt(event.id) + 1;
            this.handleCarouselClick(next);
            // alert(next);
        }
    }

    handleMouseDown(event){
        // alert(clickId);
        // target card
        let target = event.target;
        // move position
        let offset = 0;
        // initial mouse click
        let initialX = event.clientX; 

        document.onmousemove = this.onMouseMove(event, offset, initialX);

        function onMouseUp(event){
            //clear the listening handler
            document.onmousemove = null;
            document.onmouseup = null;
        }


    }


    render()
    {
        const bannerlink = this.state.bannerLink;
        const clickId = this.state.clickId;

        const width = 1280; //banner width
        let position = 0;
        position -= width * clickId;
        //#region POSITION LOGIC
        // set up left position if click slide index is 0 then left is 0
        // if click index is 1 then the left slide index 1 is 0
        // left:(width * index) <= is first setup postitioni,  + position <= adjusted position by click id
        //#endregion
      
        
        return(
            <React.Fragment>
                <div className="CarouselContainer">
                {
                    this.state.bannerLink.map((n,index) =>  
                        {
                            const path = '../assets/banner/' + n.banner
                            const image = require('../assets/banner/' + n.banner)
                            let className = clickId == index ? 'flip' : 'flip prevOrnext';
                            return(
                                <React.Fragment key = {index}>
                                <div    id = {index}
                                        className= {className}
                                        style={{ left:(width * index) + position ,
                                                backgroundImage: `url(${ image })`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}
                                        onMouseDown={this.handleMouseDown} >
                                </div>
                                </React.Fragment>
                            );
                        }
                    )

            //#region API TO DYNAMIC IMG PATH LOGIC
            // render() {
            //     return (
            //       <div>
            //         {/* looping through all the post that were retrieve from an API */}
            //         { this.props.posts.map(post => {
            //           // here we are dynamically creating a path to the image
            //           // require() returns a string to the path of the image
            //           const image = require('../../assets/' + post.character + '.png');
            
            //           return (
            //             // we put the image variable we made earlier into the src attribute
            //             <img key={ post.id } src={ image } alt={ post.character } />
            //           );
            //         }) }
            //       </div>
            //     );
            //   }
//#endregion
   
                }
                </div>
                <div className='slideButton'>
                    <SlideButtonGroup 
                        clickId={clickId} //parent state clickid pass to child constructor
                        slideQty={bannerlink} onCarouselButtonClick={this.handleCarouselClick}
                        />
                </div>
            </React.Fragment>
        );
    }
}
       
export default Carousel

class SlideButtonGroup extends React.Component{
    constructor(props){
        super(props);
        //this.state = {clickId : -1}
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e){
        this.props.onCarouselButtonClick(e.target.id);
        //this.setState({clickId : e.target.id});
    }

    render(){
        const id = this.props.clickId;
        const slideQty = this.props.slideQty;
        const buttonGroup = slideQty.map(
            (buttonLabel, index) =>
                ( <div id={index} key={index} 
                   onClick={this.handleClick}
                   className={ id == index ? "slide_button_clicked" : ""}>
                  </div> )
        );

        return(
            <React.Fragment>
            {buttonGroup}
            </React.Fragment>
        );
    }
}


// class SlideButton extends React.Component{
//     constructor(props){
//         super(props);
//         //this.state = {clickId : -1}
//         this.handleClick = this.handleClick.bind(this);
//     }

//     handleClick(e){
//         this.props.onCarouselClick(e.target.id);
//         //this.setState({clickId : e.target.id});
//     }

//     render(){
//         const id = this.props.clickId;

//         return(
//             <React.Fragment>
//             <div id={id} key={id} 
//                  onClick={this.handleClick}
//                  className={this.props.className}>
//             </div>
//             </React.Fragment>
//             // id={this.props.id} onClick={this.handleClick} className={style}
//         );
//     }
// }
