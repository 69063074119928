//icon
import ConfirmationNumberTwoToneIcon from '@mui/icons-material/ConfirmationNumberTwoTone';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import BadgeTwoToneIcon from '@mui/icons-material/BadgeTwoTone';
import LocalPhoneTwoToneIcon from '@mui/icons-material/LocalPhoneTwoTone';
import VerifiedUserTwoToneIcon from '@mui/icons-material/VerifiedUserTwoTone';

const PatientCard2 = (props)=>{
    // const row = props.selDoctor;
    // let photo;
    // let DefaultPhoto;
    // if(row.photo !== "-" && row)
    //   photo = require('../assets/doctor/' + row.photo)
    // else
    //   photo = require('../assets/doctor/doctorDefault.png')
    
   return(
    <div className="PatientCard">
       <div><h4>Data Pasien</h4></div>
       <div><ConfirmationNumberTwoToneIcon/> &nbsp; Nomor Antrian : <p>{props.data.bookingId}</p> </div>
       <div><AccountCircleTwoToneIcon/> &nbsp; Nama Pasien : <p>{props.data.patientName}</p> </div>
       <div><BadgeTwoToneIcon/> &nbsp; NIK : <p>{props.data.id}</p> </div>
       <div><LocalPhoneTwoToneIcon/> &nbsp; Nomor Hp :<p>{props.data.phone}</p> </div>
       <div><VerifiedUserTwoToneIcon/> &nbsp; Status :<p>Terverifikasi </p></div> 
    </div>
    );
  }

export default PatientCard2;
