import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { Url } from '../global';

//FORM INPUT
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';


//MODAL 
import Modal from '@mui/material/Modal';

//ICON
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import CloseIcon from '@mui/icons-material/Close';

const VideoViewer = (props) => {
    const [currentIndex, setCurrentIndex ]= useState(0);
    let link = "https://www.youtube.com/embed/" + props.videoContent.VideoId +"?controls=1&autoplay=1";
      return(
        <Modal
          open={props.open}
          onClose={props.handleInformation_Close}  
          aria-labelledby="Infomation Viewer"
          aria-describedby="Infomation Viewer"
          className="ModalVideoStyle"
        >
  
          <div className="ModalVideoContainer">
              <div className = "TitleBar">
                  <IconButton aria-label="close" style={{borderRadius:"0"}} onClick={props.handleInformation_Close}>
                      <CloseIcon />    
                  </IconButton>
              </div>

              <div className="ControlContainer">
                  <div className="VideoFrameContainer">
                        <iframe
                        className="VideoFrame"
                        auto
                        src={link}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                        >

                        </iframe>

                  </div>
              </div>

              <div className="ModalContent">
                  <IconButton aria-label="like">
                      <FavoriteIcon />    
                  </IconButton>
                  
                  <IconButton aria-label="share">
                      <ShareIcon />
                  </IconButton>

                  <h3>{props.videoContent.Header}</h3>
                  <p className="Datetime">{props.videoContent.CreateDate} </p>
                  <p className="ContentParagraph">{props.videoContent.Content}</p>
              </div>

              
              
  
          </div>
        </Modal>
      );
   
  }
  
  
  export default VideoViewer;
  