import React, {useState, useEffect, useCallback} from 'react';
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import { Url } from '../global';

//COMPONENT
import {Tabs, DoctorCard} from "./Tab";
import DoctorCardMini from "./DoctorCardMini";
import Step from './Step'
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

//INPUT
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

//DATE PICKER
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import StaticDatePicker from '@mui/lab/StaticDatePicker';

// import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';

// import DatePicker from 'react-datepicker'
import { DatePicker } from 'antd'

//CSS & ICON
import './Booking.css';



let timeSlot =[];
let row = [];

const Booking =()=> {

  let param = useParams();
  const [doctor, setDoctor] = useState([])
  const [state, setState] = React.useState("MenuDatetime");
  const [selDate, setSelDate] = React.useState(new Date());
  const [selTime, setSelTime] = React.useState();
  const [inputCheck, setInputCheck] = React.useState(false);
  let selDateFormat;

  useEffect(() => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'id': param.id })
    };

    // fetch('http://rsgrahahermine.id/android/get_doctorbyid.php', requestOptions)
    //fetch(`http://localhost:2000/api/grahahermine/getdoctor/${param.id}`)

    fetch(Url + '/android/get_doctorbyid.php', requestOptions)
    .then(response => response.json())
    .then(data => setDoctor(data))

    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  },[])

  // let step = require('../assets/step3.svg')
  const handleTime_Click = (event)=>{
    setState("MenuPatientData");
    setSelTime(event.target.value);
    setInputCheck(true);
  }

  const BasicAlerts = (msg)=> {
    return (
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity="error">This is an error alert — check it out! {msg}</Alert>
      </Stack>
    );
  }

  const navigate = useNavigate();
  //const HandleProses_Click = useCallback(() => navigate(`/bookingpatientdt/${row.id}&${selDateFormat}&${selTime}`), [navigate]);
  // const HandleProses_Click = () => navigate(`/bookingpatientdt/${row.id}&${selDateFormat}&${selTime}`);
  const HandleProses_Click = () => navigate(`/bookingpatientdt`, {state: {id: row.id,  selDateFormat: selDateFormat, selTime: selTime} });

  const HandleValidate_Click = () =>
  {
      if(selTime == undefined )
      {
        //setState(true);
        alert("Please Choose Date and Time!")
      }
  }




  const handleChange = (props)=>{
    setSelTime(undefined);
    // let dateformat = `${props.year()}-${props.month()+2}-${props.day()}`
    // console.log('change',dateformat);
    setSelDate(props.toDate())
    console.log('change',props.toDate());

    setState('MenuDatetime')
  }


  return (

    <div className="BookingContainer">
    <div className="BookingBox">
        <div className="Top">
        <h2>Buat Janji Temu dengan: </h2>
        </div>

        <div className={"Bottom-Horizontal"}>
            <div className="Left">
                <Step state={"MenuDatetime"}/>
                {
                  doctor.map((item, index)=>{
                    row = item;
                    // selDateFormat = selDate.date() + '-' + selDate.month() + '-' + selDate.year();
                    selDateFormat = `${selDate.getDate()}-${selDate.getMonth()+1}-${selDate.getFullYear()}`
                    return(  <DoctorCardMini key={index} selDoctor={item} selDate= {selDateFormat} selTime = {selTime} /> );
                  })
                }
            </div>

            <div className="Right">
                <BookingMenu state={state} seldate ={selDate}
                  handleTime={handleTime_Click}
                  handleChange={handleChange}

                />
            </div>
        </div>

        <Button onClick={inputCheck == true ? HandleProses_Click : HandleValidate_Click} style={{float: "right", width:"10%", height:"20%"}} variant="contained" >
          {/* <Link to={{pathname: `/bookingpatientdt/${row.id}`}}> PROSES </Link>  */}
          PROSES
        </Button>


    </div>
    </div>
  );
}

const BookingMenu = (props)=>{

  //FIRST PAGE

    return(
      <React.Fragment>
      {/* <div className="DateContainer"> */}

      {/* <LocalizationProvider dateAdapter={AdapterDateFns} style={{border:"1px solid gray"}}>
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          openTo="day"
          value={props.seldate}
          onChange={(newValue) => {
            props.handleChange(newValue)
            // setSelTime(null);
            // setSelDate(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider> */}
      {/* </div> */}

      <div className="TimeContainer">
        <div>Pilih Tanggal :</div>

        <DatePicker mode='date' onChange={props.handleChange} style={{marginTop: "1em"}}/>

        <div style={{marginTop: "2em"}}>Pilih Jam : </div>
        {
          <TimeButton timeClick={props.handleTime} seldate= {props.seldate} selDoctor={row}/>
        }
      </div>
      </React.Fragment>
    );
}

const TimeButton = (props)=>{

  const item = props.selDoctor;
  const date = props.seldate;
  let time;
  let today = new Date();

  if(date.getFullYear() > today.getFullYear() ){
    if(date.getDay() == 1)     {time = item.monday}
    else if(date.getDay() == 2){time = item.tuesday}
    else if(date.getDay() == 3){time = item.wednesday}
    else if(date.getDay() == 4){time = item.thursday}
    else if(date.getDay() == 5){time = item.friday}
    else if(date.getDay() == 6){time = item.saturday}
    else if(date.getDay() == 7){time = item.sunday}
  }

  if(date.getMonth() > today.getMonth()){
    if(date.getDay() == 1)     {time = item.monday}
    else if(date.getDay() == 2){time = item.tuesday}
    else if(date.getDay() == 3){time = item.wednesday}
    else if(date.getDay() == 4){time = item.thursday}
    else if(date.getDay() == 5){time = item.friday}
    else if(date.getDay() == 6){time = item.saturday}
    else if(date.getDay() == 7){time = item.sunday}
  }

  if(date.getMonth() == today.getMonth() && date.getDate() >= today.getDate()){
    if(date.getDay() == 1)     {time = item.monday}
    else if(date.getDay() == 2){time = item.tuesday}
    else if(date.getDay() == 3){time = item.wednesday}
    else if(date.getDay() == 4){time = item.thursday}
    else if(date.getDay() == 5){time = item.friday}
    else if(date.getDay() == 6){time = item.saturday}
    else if(date.getDay() == 7){time = item.sunday}
  }

  if(time == null){
    timeSlot = "not available"
    return(
           <Button variant="contained" color="error" style={{marginTop:"1em", width:"100%"}}>{timeSlot}</Button>
    );
  }else{
    timeSlot = time.split(',');
    return(
      timeSlot.map((item, index)=>{
         return(
               <Button onClick={props.timeClick} style={{background: "var(--primary)", width:"100%", marginTop:"1em"}} variant="contained" key={index} value={item}>{item}</Button>
         );
      })

    );
  }
}

export default Booking;
