import React, { useState, useEffect, useCallback } from "react";
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import { Url } from "../global";

//COMPONENT
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import AwardsViewer from "../page/AwardsViewer";

//STYLE & ICON
import "./Awards.css";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { ReactComponent as PlayWatermark } from "../assets/play.svg";
import { ReactComponent as PlayWatermarkActive } from "../assets/playActive.svg";

export const Awards = (props) => {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [openAwards, setOpenAwards] = useState(false);
  const [awardsId, setAwardsId] = useState(1);
  const [awardsContent, setAwardsContent] = useState([]);

  const [awardsList, setAwardsList] = useState([]);

  useEffect(async () => {
    // const response = await fetch('http://rsgrahahermine.id/android/get_awards.php');
    const response = await fetch(Url + "/android/get_awards.php");

    const json = await response.json();
    setAwardsList(json);
  }, []);

  useEffect(async () => {}, [awardsList]);

  const width = 520; //banner width
  let position = 0;
  position -= width * currentIndex;
  let itemCount = awardsList.length;

  const HandleNext_Click = () => {
    let index = currentIndex + 1;
    // if current index is more than item count then go back to first
    if (index > itemCount - 2) {
      index = 0;
    }
    setCurrentIndex(index);
  };

  const HandlePrev_Click = () => {
    let index = currentIndex - 1;
    // if current index is more lest than first index then go to last item
    if (index < 0) {
      index = itemCount - 2;
    }
    setCurrentIndex(index);
  };

  const AwardsViewer_Open = (event) => {
    setAwardsId(event.currentTarget.id);
    setOpenAwards(true);
  };

  const AwardsViewer_Close = () => {
    setOpenAwards(false);
  };

  return (
    <div className="AwardsContainer">
      {awardsList.map((item, index) => {
        // alert(awardsId)
        if (index + 1 == awardsId) {
          return (
            <AwardsViewer
              open={openAwards}
              handleAwards_Close={AwardsViewer_Close}
              Id={awardsId}
              Photo={item.Photo}
              Header={item.Header}
              Content={item.Content}
              CreateDate={item.CreateDate}
            />
          );
        }
      })}

      <h1>Penghargaan: </h1>
      <div className="ControlContainer">
        <IconButton onClick={HandlePrev_Click} style={{ borderRadius: "0" }}>
          <ArrowBackIosNewIcon />
        </IconButton>

        <div className="AwardsBox">
          {awardsList.map((item, index) => {
            const path = "assets/awards/" + item.Thumbnail;
            // const image = require("../" + path); // <-- this is for build from source

            return (
              <Card
                key={index}
                className="AwardsCard"
                style={{ left: width * index + position }}
                id={item.Id}
              >
                <div
                  className="MediaContainer"
                  style={{ backgroundImage: "url(" + path + ")" }}
                  onClick={AwardsViewer_Open}
                  id={item.Id}
                ></div>

                <CardContent
                  className="Content"
                  id={item.Id}
                  onClick={AwardsViewer_Open}
                >
                  <h3>{item.Header}</h3>
                  <p className="Datetime">{item.CreateDate} </p>
                </CardContent>
              </Card>
            );
          })}
        </div>

        <IconButton onClick={HandleNext_Click} style={{ borderRadius: "0" }}>
          <ArrowBackIosNewIcon style={{ transform: "rotate(180deg)" }} />
        </IconButton>
      </div>
    </div>
  );
};

export default Awards;
