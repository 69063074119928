import IconButton from "@mui/material/IconButton";


//STYLE & ICON
import './Footer.css';
import Logo_GH from '../assets/logoText.png';
import KARS from '../assets/KARS.png';
import BPJSKes from '../assets/partner/BPJSKesehatan.png';
import BPJSKet from '../assets/partner/BPJSKetenagakerjaan.png';

import IconInstagram from "@mui/icons-material/Instagram";
import IconFacebook from '@mui/icons-material/Facebook';
import IconYouTube from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import { ReactComponent as Mandiri } from "../assets/mandiri.svg";

const Footer = (props)=>{
   return(
    <div className="FooterContainer">
        <div className="Top">
            <div className="Box">
                <div className="Title">
                    <img src={Logo_GH} className="logo_gh_footer" />
                </div>
                <div className="Content">
                    <p>
                    Memberikan pelayanan kesehatan yang cepat, tepat dan berkualitas serta
                    Mengutamakan kenyamanan dan keselamatan pasien.
                    </p>
                </div>

            </div>

            <div className="Box">
                <div className="Title">
                    <p> Awards </p>
                </div>
                <div className="Content">
                    <Mandiri className="logo_mandiri_footer"/>
                    <img src={KARS} className="logo_kars_footer" />
                </div>
            </div>

            <div className="Box">
                <div className="Title">
                    <p> Partnership </p>
                </div>
                <div className="Content">
                    <img src={BPJSKes} className="logo_bpjs_footer" />
                    <img src={BPJSKet} className="logo_bpjs_footer" />
                </div>
            </div>

            <div className="Box">
                <div className="Title">
                    <p> Follow Us </p>
                </div>
                <div className="Content">
                    <IconButton aria-label="facebook" className="FooterButton">
                      <div className="icon"><IconFacebook className="iconColor"/></div> <div className="text"> rs_grahahermine </div>
                      {/* <IconFacebook className="iconColor"/> rs_grahahermine */}
                    </IconButton>

                    <IconButton aria-label="instagram" className="FooterButton">
                        <div className="icon"><IconInstagram className="iconColor"/></div> <div className="text">rs_grahahermine </div>
                    </IconButton>

                    <IconButton aria-label="youtube" className="FooterButton">
                        <div className="icon"><IconYouTube className="iconColor"/></div> <div className="text">Rumah Sakit Graha Hermine </div>
                    </IconButton>


                    <IconButton aria-label="linkedin" className="FooterButton">
                        <div className="icon"><LinkedInIcon className="iconColor"/></div> <div className="text">Rumah Sakit Graha Hermine </div>
                    </IconButton>


                </div>
            </div>
        </div>
        <div className="Bottom">
          Rumah Sakit Graha Hermine -  PT. Batam Sehat Madani , Copyright © 2022
        </div>


    </div>
    );
  }

export default Footer;
