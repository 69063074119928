import React, {useState, useEffect}  from 'react';
import DoctorCard from "./DoctorCard";
import { Url } from '../global';
import { Link } from "react-router-dom";

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Chip from '@mui/material/Chip';


import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import MedicalServicesTwoToneIcon from '@mui/icons-material/MedicalServicesTwoTone';

import './Schedule.css';



const Schedule = (props)=>{
    const [doctorList, setDoctorList] = useState([]);
    const [speciaList, setSpeciaList] = useState([]);
    const [selected, setSelected] = useState();

    useEffect(
        () => {

            async function fetchData() {
                const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'specialist' : ''})
                };
            
                const response = await fetch(Url + '/android/get_specialist.php', requestOptions);
                const json = await response.json();
                setSpeciaList( json );
                
                ////////////////////////////////////////////
                
                const doctorRequestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 'name' : '', 'specialist' : '', 'day' : ''})
                    };

                const doctorResponse = await fetch(Url + '/android/get_doctorlist.php', doctorRequestOptions);
                const doctorJson = await doctorResponse.json();
                setDoctorList( doctorJson );
            }
        
            fetchData();
        }   
    ,[])

    let specialistArray = speciaList.map(item => item.specialist) // json object selected specialist field only

    const Chip_handleClick = (label) => {
        const doctorRequestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'name' : 'null', 'specialist' : `${label}`, 'day' : 'null'})
            };
        // dont know why only with .then can cause the state to rerender
        fetch(Url + '/android/get_doctorlist.php', doctorRequestOptions)
        .then((res) => res.json())
        .then((json)=> { setDoctorList(json); } );
    }

    return(
       
        <div className="ScheduleContainer"> 
        <p>Silahkan pilih Specialisasi dokter yang anda butuhkan : </p>
             
        <div className ="chipContainer" >

        {
            specialistArray.map(
                (item,index)=>
                {
                    return(
                        <Chip key= {index}
                                label= {item}
                              variant="outlined"
                            //   color="secondary"
                                style={{margin: "0.2em"}} 
                                onClick={() => Chip_handleClick(item)}/>
                    )
                }
            )

        }
        </div>

        <div className="ScheduleBox"> 
            {
                doctorList?.map(
                    (item,index)=>
                    {
                        return(
                            <InformationBox key={index} data= {item}/>
                             
                            // <DoctorCard key={index} row= {item}/>

                        );
                    }
                )
            }
        </div>
        </div>
    );
}


const InformationBox = (props) =>
{

    const photo = props.data.photo == "-" ? "doctorDefault.png" : props.data.photo;
    const path = "assets/doctor/" + photo;
    // const image = require("../" + path); // <-- this is for build from source
    // `url(${ image    })`                 // <--use in return block
                        
    return (    <Link to={{pathname: `/booking/${props.data.id}`}} > 
                <Card className="DoctorGalleryCard" id={props.data.key} >
                    <div id= {props.data.id} className="Media" style={{ backgroundImage: `url(${ path })`}}
                        // onClick={props.InformationViewer_Open}
                        >
                    </div>
             
                    <CardContent className="Content" id={props.data.key} >
                        <div className="Name">{props.data.name}</div>
                        <p className="Specialist"><MedicalServicesTwoToneIcon/>&nbsp; {props.data.specialist} </p>
                    </CardContent>
                    
                    <Button variant="contained" size="small" style={{textTransform: "none", background: "var(--primary)", width: "45%"}}  >
                        {/* <Link to={`/booking/${row}`}> BUAT JANJI </Link>  */}
                      Buat Janji 
            
                    </Button>

                    {/* <div className="Info">Buat Janji</div> */}
                    {/* <CardActions>
                        <IconButton aria-label="add to favorites">
                        <FavoriteIcon />
                        </IconButton>
                        <IconButton aria-label="share">
                        <ShareIcon />
                        </IconButton>
                    </CardActions>  */}
                </Card>
                </Link> 
    )


}


export default Schedule;
