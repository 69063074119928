import MedicalServicesTwoToneIcon from '@mui/icons-material/MedicalServicesTwoTone';
import CalendarTodayTwoToneIcon from '@mui/icons-material/CalendarTodayTwoTone';
import AccessTimeTwoToneIcon from '@mui/icons-material/AccessTimeTwoTone';

const DoctorCard2 = (props)=>{
    
    let photo;
    let DefaultPhoto;
    if(props.data.photo !== "-" && props.data)
      photo = '/assets/doctor/' + props.data.photo;
    else
      photo = '/assets/doctor/doctorDefault.png';

   return(
    <div className="DoctorCard2">
      <div className="top">
        <div className="photo" style={{backgroundImage: `url(${ photo })`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
        </div>
        <div className="info">
          <h4>{props.data.name} </h4>
          <p> <MedicalServicesTwoToneIcon/>  &nbsp; Spesialis : {props.data.specialist} </p>
          <p> <CalendarTodayTwoToneIcon/> &nbsp; Tanggal : {props.data.bookDate} </p>
          <p> <AccessTimeTwoToneIcon/> &nbsp; Jam : {props.data.bookTime} </p>
        </div>
      </div>

      {/* <div className="bottom">
            <div><div className="thead">Senin</div>  <div className="trow">{props.selDoctor.monday}</div></div>
            <div><div className="thead">Selasa</div> <div className="trow">{props.selDoctor.tuesday}</div></div>
            <div><div className="thead">Rabu</div>   <div className="trow">{props.selDoctor.wednesday}</div></div>
            <div><div className="thead">Kamis</div>  <div className="trow">{props.selDoctor.thursday}</div></div>
            <div><div className="thead">Jumat</div>  <div className="trow">{props.selDoctor.friday}</div></div>
            <div><div className="thead">Sabtu</div>  <div className="trow">{props.selDoctor.saturday}</div></div>
            <div><div className="thead">Minggu</div> <div className="trow">{props.selDoctor.sunday}</div></div>
        </div> */}
    </div>
    );
  }

export default DoctorCard2;
