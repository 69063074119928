import React from 'react';


// class Calculator extends React.Component {
//     constructor(props){
//         super(props);
//         this.handleChange = this.handleChange.bind(this);
//         this.state = {temperature: ''};
//     }

//     handleChange(e){
//         this.setState({temperature: e.target.value});        
//     }

//     render(){
//         const temperature = this.state.temperature;
//         return(
//             <fieldset>
//                 <legend>Enter temperature in Celcius:</legend>
//                 <input
//                     value ={temperature}
//                     onChange= {this.handleChange}
//                 />
//                 {temperature}
//                 <BoilingVerdict celcius={parseFloat(temperature)}/>
//             </fieldset>
//         );
//     }

// }



function BoilingVerdict(props){
    if(props.celcius >= 100){
        return <p> The water would boil. </p>;
    }
    return <p> the water would not boil. </p>;
}

const scaleNames ={
    c: 'Celcius',
    f: 'Fahrenheit'
}

function toCelcius(fahrenheit){
    return (fahrenheit - 32) * 5 / 9;
}

function toFahrenheit(celcius){
    return (celcius * 9 / 5) + 32;
}



function tryConvert(temperature, convert){
    const input = parseFloat(temperature);
    if(Number.isNaN(input)){
        return '';
    }
    const output = convert(input);
    const rounded = Math.round(output * 1000) / 1000;
    return rounded.toString();
}

class TemperatureInput extends React.Component {
    constructor(props){
        super(props);
        this.handleChange = this.handleChange.bind(this);
//      this.state = {temperature: '', scale: 'c'};
    }

    handleChange(e){
//      before: this.setState({temperature: e.target.value});        
        this.props.onTemperatureChange(e.target.value);        
    }

    render(){
//      before: const temperature = this.state.temperature;
        const temperature = this.props.temperature;
        const scale = this.props.scale;
        return(
            <fieldset>
                <legend>Enter temperature in {scaleNames[scale]}:</legend>
                <input
                    value ={temperature}
                    onChange= {this.handleChange}
                />
                {/* {tryConvert(temperature)}
                <BoilingVerdict celcius={parseFloat(temperature)}/> */}
            </fieldset>
        );
    }
}

class Calculator extends React.Component {
    constructor(props){
        super(props);
        this.handleCelciusChange = this.handleCelciusChange.bind(this);
        this.handleFahrenheitChange = this.handleFahrenheitChange.bind(this);
        this.handleZZZChange = this.handleZZZChange.bind(this);

        this.state = {temperature: '', scale: 'c'};
    }

    handleCelciusChange(a){
        this.setState({scale: 'c', temperature : a});
    }

    handleFahrenheitChange(temperature){
        this.setState({scale: 'f', temperature});
    }

    handleZZZChange(temperature){
        this.setState({scale: 'z', temperature});
    }

    render(){
        const scale = this.state.scale;
        const temperature = this.state.temperature;
        const celcius = scale === 'f' ? tryConvert(temperature, toCelcius) : temperature;
        const fahrenheit = scale === 'c' ? tryConvert(temperature, toFahrenheit) : temperature;


        return(
            <div>
               <TemperatureInput scale="c" temperature={celcius} onTemperatureChange={this.handleCelciusChange}/>
               <TemperatureInput scale="f" temperature={fahrenheit} onTemperatureChange={this.handleFahrenheitChange}/>
    {scale}
               <BoilingVerdict celcius = {celcius}/>
            </div>
        );
    }

}

export default Calculator;

// 1. TemperatureInput input 1 call onChange
// 2. onChange call handleChange call Calculator onTemperatureChange call handleCelciusChange
// 3. Calculator SetState
// 4. Calculator re Render with new converted value
