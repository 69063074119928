import React from 'react';


// function Form(){
//     function handleSubmit(e){
//         e.preventDefault();
//         console.log('you click submit');
//     }

//     return(
//         <form onSubmit={handleSubmit}>
//             <button type="submit"> submit </button>
//         </form>
//     );
// }

class toggle extends React.Component{
    constructor(prop){
        super(prop);
        this.state = {isToggleOn: true};
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(){
        this.setState(
            prevState => ({isToggleOn: !prevState.isToggleOn})
        );
    }

    render(){
        return(
            <button onClick={this.handleClick}>
                {this.state.isToggleOn ? 'ON' : 'OFF'}
            </button>
        );
    }
}


class HermineForm extends React.Component{
    constructor(props){
        super(props);
        this.state = {value: 'this is text area'};

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event){
        this.setState({value: event.target.value});   
    }

    handleSubmit(event){
        alert('A name was submitted: ' + this.state.value);
        event.preventDefault();
    }

    render(){
        return(
            <form onSubmit={this.handleSubmit}>
                <label>
                    Name:
                    <input type ="text" value={this.state.value} onChange={this.handleChange} />
                    <textarea value={this.state.value} onChange={this.handleChange} />

                </label>
                <input type="submit" value="submit value" />
            </form>
        );
    }
} 


class FlavorForm extends React.Component{
    constructor(props){
        super(props);
        this.state = {value: ''};

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event){
        this.setState({value: event.target.value});   
    }

    handleSubmit(event){
        alert('A flavor you choose: ' + this.state.value);
        event.preventDefault();
    }

    render(){
        return(
            <form onSubmit={this.handleSubmit}>
                <label>
                  pick your favorite flavor:
                  <select value={this.state.value} onChange={this.handleChange}>
                    <option value = "grapefruit">grapefruit</option>
                    <option value = "banana">banana</option>
                    <option value = "orange">orange</option>
                    <option value = "peach">peach</option>
                  </select>

                </label>
                <input type="submit" value="submit value" />
            </form>
        );
    }
} 


class Reservation extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            isGoing: true,
            numberOfGuests: props.numberOfGuests
        };

        this.HandleInputChange = this.HandleInputChange.bind(this);
        this.HandleClick = this.HandleClick.bind(this);
    }

    HandleInputChange(event){
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name] : value
        });
    }

    HandleClick(event){
        alert('A flavor you choose: ' + this.state.numberOfGuests);
        event.preventDefault();

    }

    render(){
        return(
            <form onSubmit={this.HandleClick}>
                <label>
                    Is going:
                    < input
                      name="isGoing"
                      type="checkbox"
                      checked={this.state.isGoing}
                      onChange={this.HandleInputChange} /> 
                </label>
                
                <br/>
                
                <label>
                    Number of guests:
                    < input
                      name="numberOfGuests"
                      type="number"
                      value={this.state.numberOfGuests}
                      onChange={this.HandleInputChange} />
                </label>

                <input type="submit" />
            </form>
        );

    }



}



class UncontrolComponent extends React.Component{
    constructor(props){
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.input = React.createRef();
        this.fileInput = React.createRef();
    }


    handleSubmit(event){
        alert('A flavor you choose: ' + this.fileInput.current.files[0].name);
        event.preventDefault();

    }

    render(){
        return(
            <form onSubmit={this.handleSubmit}>
                <label>
                    Name:
                    < input
                      defaultValue="test"
                      type="text"
                      ref={this.input} /> 

                    < input
                      type="file"
                      ref={this.fileInput} /> 


                </label>
                <input type="submit" />
            </form>
        );

    }



}


export default UncontrolComponent;
