
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import './HowToStep.css';


export const HowToStep = (props) =>
{
  let icon1 = require('../assets/step/choose_doctor_1.png');
  let icon2 = require('../assets/step/choose_datetime_2.png');
  let icon3 = require('../assets/step/verify_3.png');
  let icon4 = require('../assets/step/goto_hospital_4.png');


  return(
      <div className="HowToContainer">
          <div className="BoxHowto">
              <div className="Top">
                  <div className="IconBox">
                      <p>1. Pilih Dokter</p>
                      <div className="IconStep" style={{backgroundImage : `url(${icon1})`}} ></div>
                  </div>
                        <ArrowForwardIcon fontSize="large" />
                  <div className="IconBox">
                      <p>2. Pilih Tanggal & Waktu</p>
                      <div className="IconStep" style={{backgroundImage : `url(${icon2})`}} ></div>
                  </div>
                        <ArrowForwardIcon fontSize="large"/>
                  <div className="IconBox">
                      <p>3. Masukkan Data Pasien</p>
                      <div className="IconStep" style={{backgroundImage : `url(${icon3})`}} ></div>
                  </div>
                        <ArrowForwardIcon fontSize="large"/>
                  <div className="IconBox">
                      <p>4. Datang ke Rumah Sakit</p>
                      <div className="IconStep" style={{backgroundImage : `url(${icon4})`}} ></div>
                  </div>
              </div>

          </div>
      </div>
    );
}