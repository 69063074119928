import { ReactComponent as Step0 } from "../assets/step0.svg";
import { ReactComponent as Step1 } from "../assets/step1.svg";
import { ReactComponent as Step2 } from "../assets/step2.svg";
import { ReactComponent as Step3 } from "../assets/step3.svg";

const Step = (props)=>{
 
    if(props.state == "MenuDatetime"){
      return(<Step0/>);
    }
    else if(props.state == "MenuPatientData"){
      return(<Step1/>);
    }
    else if(props.state == "MenuVerification"){
      return(<Step2/>);
    }
    // else if(props.state == "datetime"){
    //   return(<Step3/>);
    // }
  
  }

export default Step;
