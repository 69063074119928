import React, {useState, useEffect, useCallback} from 'react';
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import { Url } from '../global';

//COMPONENT
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import VideoViewer from "../page/VideoViewer";

//STYLE & ICON
import './Video.css';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { ReactComponent as PlayWatermark } from "../assets/play.svg";
import { ReactComponent as PlayWatermarkActive } from "../assets/playActive.svg";


export const Video = (props)=>{
const [currentIndex, setCurrentIndex] = React.useState(0);
const [openInformation, setOpenInformation] = useState(false);
const [Id, setId] = useState(1);
const [videoHover, setVideoHover] = useState([]);
const [videoList, setVideoList] = useState([]);
const [videoContent, setVideoContent] = useState([]);

useEffect(async() => {
  // const requestOptions = {
  //   method: 'POST',
  //   headers: { 'Content-Type': 'application/json' },
  //   body: JSON.stringify({ 'articleId': articleId })
  // };

  const response = await fetch(Url + '/android/get_video.php');
  const json = await response.json();
  setVideoList( json );
  setVideoHover(()=> videoList.map(() => false));

},[])

useEffect(async() => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ 'Id': Id })
  };
  const response = await fetch(Url + '/android/get_video_content.php', requestOptions);
  const json = await response.json();

  setVideoContent( json[0] );
},[Id])


const width = 520; //banner width
let position = 0;
position -= width * currentIndex;

let itemCount = videoList.length;

const HandleNext_Click = ()=>{
    let index = currentIndex + 1
    // if current index is more than item count then go back to first
    if(index > (itemCount - 2)){
          index = 0;
    }

    setCurrentIndex(index);
}

const HandlePrev_Click = ()=> {
    let index = currentIndex - 1
    // if current index is more lest than first index then go to last item
    if(index < 0 ){
          index = itemCount - 2;
    }

    setCurrentIndex(index);
}

const InformationViewer_Open = (event)=>{
    setId(event.currentTarget.id);
    setOpenInformation(true);
}

const InformationViewer_Close = ()=>{
    setOpenInformation(false);
}

const handleOver = (event) => {
    //setVideoHover({'id' : event.target.id, 'status' : true });
  // event.target.style.background = 'var(--secondary)';
  // event.target.style.color = 'white';
  // setPatientData(prevData => ( {...prevData, "DOB" : event} ))

  const newVisibilities = [...videoHover];
  newVisibilities[event.currentTarget.id] = true;
  setVideoHover(newVisibilities);

};

const handleOut = (event) => {
  const newVisibilities = [...videoHover]; // store hover state to temp variable
  newVisibilities[event.currentTarget.id] = false; // set target id status in temp variablee
  setVideoHover(newVisibilities); // set to videoHover state
};

return(
        <div className="VideoContainer" >
        <VideoViewer Id= {Id}
                      videoContent={videoContent}
                      open={openInformation}
                      handleInformation_Close={InformationViewer_Close} />

        {/* {      JSON.stringify(videoHover)} */}
        <h3>VIDEO KESEHATAN: </h3>

            <div className="ControlContainer">

                <IconButton onClick={HandlePrev_Click} style={{borderRadius:"0"}}>
                    <ArrowBackIosNewIcon />
                </IconButton>

                <div className="VideoBox">
                {   videoList.map((item, index)=>{
                    let video_tn = "https://img.youtube.com/vi/" + item.VideoId + "/maxresdefault.jpg";
                    // const path = "assets/article/" + props.photo;
                    // const image = require("../" + path);
                        return(
                                <Card key={index}
                                      className="VideoCard"
                                      style ={{ left:(width * index) + position }}
                                      id ={item.Id}
                                      onMouseOver={handleOver}
                                      onMouseOut={handleOut}>

                                    <div className="MediaContainer">
                                        {videoHover[item.Id] ? <PlayWatermarkActive className="PlayWatermark" onClick={InformationViewer_Open}/> : <PlayWatermark className="PlayWatermark" onClick={InformationViewer_Open}/>}

                                        <CardMedia className= "VideoMedia"
                                                    component="img"
                                                    id={item.Id}
                                                    image= {video_tn}
                                                    onClick={InformationViewer_Open} />
                                    </div>

                                    <CardContent className="Content"
                                                  id={item.Id}
                                                  onClick={InformationViewer_Open}>

                                        <h3>{item.Header}</h3>
                                        <p className="VideoDatetime">{item.CreateDate} </p>
                                        <p>{item.Description}</p>
                                    </CardContent>

                                    {/* <CardActions>
                                        <IconButton aria-label="like">
                                        <FavoriteIcon />
                                        </IconButton>
                                        <IconButton aria-label="share">
                                        <ShareIcon />
                                        </IconButton>
                                    </CardActions> */}
                                </Card>
                        );
                    })
                }

                </div>
                <IconButton onClick={HandleNext_Click} style={{borderRadius:"0"}}>
                    <ArrowBackIosNewIcon style={{ transform: "rotate(180deg)"}}/>
                </IconButton>
            </div>

        </div>

    );

}


export default Video;

