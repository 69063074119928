import React, {useState, useEffect, useCallback} from 'react';
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import { Url } from '../global';

//COMPONENT
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CareerViewer from "../page/CareerViewer";

//STYLE & ICON
import './Career.css';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { ReactComponent as PlayWatermark } from "../assets/play.svg";
import { ReactComponent as PlayWatermarkActive } from "../assets/playActive.svg";


export const Career = (props)=>{
const [currentIndex, setCurrentIndex] = React.useState(0);
const [openCareer, setOpenCareer] = useState(false);
const [careerId, setCareerId] = useState(1);
const [careerContent, setCareerContent] = useState([]);

const [careerList, setCareerList] = useState([]);


useEffect(async() => {
    // const response = await fetch('http://rsgrahahermine.id/android/get_awards.php');
    const response = await fetch(Url + '/android/get_career.php');
    
    const json = await response.json();
    setCareerList( json );

},[])

useEffect(async() => {

},[careerList])

const width = 520; //banner width
let position = 0;
position -= width * currentIndex;
let itemCount = careerList.length;

const HandleNext_Click = ()=>{
    let index = currentIndex + 1
    // if current index is more than item count then go back to first
    if(index > (itemCount - 2)){
          index = 0;
    }
    setCurrentIndex(index);
}

const HandlePrev_Click = ()=> {
    let index = currentIndex - 1
    // if current index is more lest than first index then go to last item
    if(index < 0 ){
        index = itemCount - 2;
    }
    setCurrentIndex(index);
}

const careerViewer_Open = (event)=>{
    setCareerId(event.currentTarget.id);
    setOpenCareer(true);
}

const careerViewer_Close = ()=>{
    setOpenCareer(false);
}

    return(
        <div className="CareerContainer" >
            {

            careerList.map(
                (item, index)=>{
                    // alert(awardsId)
                    if((index + 1) == careerId){
                        return(
                            <CareerViewer open={openCareer}
                            handleCareer_Close={careerViewer_Close}
                            Id={careerId} 
                            Photo={item.Photo} 
                            Header={item.Header}
                            Content={item.Content}
                            CreateDate={item.CreateDate}/>
                        );
                    }
                }
            )
            }
     
        <h1>Lowongan Kerja: </h1>
            <div className="ControlContainer">

                <IconButton onClick={HandlePrev_Click} style={{borderRadius:"0"}}>
                    <ArrowBackIosNewIcon />
                </IconButton>

                <div className="CareerBox">
                {   careerList.map((item, index)=>
                    {
                    const path = "assets/career/" + item.Thumbnail;
                        return( 
                                <Card key={index}
                                      className="CareerCard"
                                      style ={{ left:(width * index) + position }}
                                      id ={item.Id}>

                                    <div className= "MediaContainer"
                                         style={{backgroundImage: "url(" + path + ")"}}
                                         onClick={careerViewer_Open}
                                         id ={item.Id}> 
                                    </div>

                                    <CardContent className="Content"
                                                  id={item.Id}
                                                  onClick={careerViewer_Open}>

                                        <h3>{item.Header}</h3>
                                        <p className="Datetime">{item.CreateDate} </p>
                                    </CardContent>
                                </Card>
                              );
                    })
                }
                </div>

                <IconButton onClick={HandleNext_Click} style={{borderRadius:"0"}}>
                    <ArrowBackIosNewIcon style={{ transform: "rotate(180deg)"}}/>
                </IconButton>

            </div>
        </div>
        
    );

   

}


export default Career;

