import React, {useState, useEffect, useCallback} from 'react';
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import { Url } from '../global';

//COMPONENT
import {Tabs, DoctorCard} from "./Tab";
import DoctorCardMini from "./DoctorCardMini";
import PatientCard from "./PatientCard";





export const PatientBookingHistory = (props)=>{
   let param = useParams();
   const [data, setData] = React.useState([]);
   let patientName;
   useEffect(async() => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'id': param.id, 'phone' : param.phone })
    };

    const response = await fetch(Url + '/android/get_bookingdata.php', requestOptions);
    const json = await response.json();
    setData( json );

   },[])



   if(props.login == true){
    return(
    
                
                <div className="PatientHisContainer">
                <div className="PatientHisBookingBox">
                    <div className="Top">
                       {data.map((item, index) => {patientName = item.patientName;}) }
                        <h3>{patientName} , Buat Janji Temu dengan: </h3>    
                    </div>
                
                    <div className={"Bottom-Horizontal"}>
                        <div className="Left">
                        {data.map((item, index) => {
                            let row = item;
                            return(  
                                <React.Fragment>
                                    <DoctorCardMini key={index} selDoctor={row} selDate={item.bookDate} selTime={item.bookTime}/> 
                                </React.Fragment>
                            );
                        })}
                        </div>

                        <div className="Right">
                            {data.map((item, index) => {
                                return(  
                                    <React.Fragment>
                                        <PatientCard key={index} row={item}/> 
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    </div>
        
                </div>
                </div>
            
        );
    }else{
        return(
            <React.Fragment>
                sorry, you are unauthorized!
            </React.Fragment>
        );
    }
}


export default PatientBookingHistory;

