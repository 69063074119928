import React, {useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";
import { Url } from '../global';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button';
import AutoComplete from '@mui/material/Autocomplete';


//ICON & STYLE
import SearchIcon from '@mui/icons-material/Search';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import Box from '@mui/material/Box';
import './Tab.css';

import DoctorCard from "./DoctorCard";
import {DataGrid} from '@mui/x-data-grid'
import { Empty } from 'antd';





const days = [
  {value:'1', label: 'Senin'},
  {value:'2', label: 'Selasa'},
  {value:'3', label: 'Rabu'},
  {value:'4', label: 'Kamis'},
  {value:'5', label: 'Jumat'},
  {value:'6', label: 'Sabtu'},
  {value:'7', label: 'Minggu'}

];

export const Tabs = (props) => {
    const [selected, setSelected] = React.useState(1);
    const [result, setResult] = React.useState([]);
    const navigate = useNavigate()
    let id = localStorage.getItem('id')
    let phone = localStorage.getItem('phone')
    // prevState = val;

    const handleClick = (props) => {
      setSelected(props.target.value);
      // prevState = val;
    };

    const handlePassData = (props) => {
      setResult(props);
      // prevState = val;
    };

    const handleDetail = () => {
      navigate(`/patient`, {state:{id: id, phone: phone}});
    }

    return (
      <React.Fragment>
      <div className="tabContainer">
        <div className="tabBox">
          <TabHeader clicked={selected} onClick={handleClick} />
            { selected == 1 ? <TabCariDokter passToParent={handlePassData}/> : null  }
            { selected == 2 ? <TabHowto onClick={handleClick} /> : null }
            { selected == 3 ? <TabJadwalJanji handleLoginTab_Click={props.handleLoginTab_Click} login={props.login} id={id} phone ={phone} handleDetail={handleDetail}/> : null }
        </div>
        <DoctorResult data = {result}/>

      </div>
      </React.Fragment>

// #region tab sample code
      // <Box sx={{ width: '100%', height: '200px', padding: '3em' }}>
      //   <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
      //     <Tabs TabIndicatorProps={{style: {background:'#2c9460'}}} inkBarStyle={{background: 'black'}}
      //      value={val} onChange={handleChange} aria-label="basic tabs example">
      //       <Tab label="Cari Dokter"  {...tabProps(0)}/>
      //       <Tab label="Buat Janji Temu"  />
      //       <Tab label="Covid 19"/>
      //     </Tabs>
      //   </Box>
      //   <TabPanel value={val} index={0}>
      //     test
      //   </TabPanel>
      //   <TabPanel value={val} index={1}>
      //     Item Two
      //   </TabPanel>
      //   <TabPanel value={val} index={2}>
      //     Item Three
      //   </TabPanel>
      // </Box>
// #endregion
    );
  }

  const TabHeader = ({clicked, onClick})=>
  {
    const handleOver = (event) => {
      // event.target.style.background = 'var(--secondary)';
      // event.target.style.color = 'white';
    };

    const handleOut = (event) => {
      // event.target.style.background = '';
      // event.target.style.color = '';
    };

    return (
        <div className="tabHeader">
        <button className= {clicked == 1 ? "tabButton tabButtonActive" : "tabButton"}
          onClick={onClick}
          onMouseOver={handleOver}
          onMouseOut={handleOut}
          value="1">
          Cari Dokter
        </button>

        <button className= {clicked == 2 ? "tabButton tabButtonActive" : "tabButton"}
          onClick={onClick}
          onMouseOver={handleOver}
          onMouseOut={handleOut}
          value="2">
          Buat Janji
        </button>

        <button className= {clicked == 3 ? "tabButton tabButtonActive" : "tabButton"}
          onClick={onClick}
          onMouseOver={handleOver}
          onMouseOut={handleOut}
          value="3">
          Cek Jadwal Anda
        </button>
        </div>
    );
  }



  const TabCariDokter = ({passToParent}) =>
  {
    // let result = [
    //   {name: 'Andi' },
    //   {name: 'Anda' },
    //   {name: 'Andiwan' },
    //   {name: 'Andalim' },
    // ];

    const [name, setName] = React.useState([]); // list for autocomplete
    const [specialist, setSpecialist] = React.useState([]); // list for autocomplete
    const [input, setInput] = React.useState({txtName:"", txtSpecialist:"", optDay:""});
    const [doctorList, setDoctorList] = React.useState([]); // list of data


    const handleName = (event, val, reason) =>
    {
      const {name, value} = event.target; // dynamic get field name and value from textfield
      setInput({...input, [name]: value}); // dynamic set field name and value from textfield
      if(value.length !== 0 && isNaN(value))
      {

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'name': value })
      };

      fetch(Url + '/android/get_doctor.php', requestOptions)
      //fetch("http://localhost:2000/api/grahahermine/getdoctorname/" + value)
      .then((res) => res.json())
      .then((json)=> { setName(json); } );

    }else{
        setName([]);

      }
    }

    const handleAutoName = (event, value, reason) => { setInput({...input, txtName : value}); // dynamic set field name and value
    }

    const handleAutoSpecialist = (event, value) => { setInput({...input, txtSpecialist : value}); // dynamic set field name and value
    }

    const handleSpecialist = (event)=>
    {
      const {name, value} = event.target; // dynamic get field name and value
      setInput({...input, [name]: value}); // dynamic set field name and value

      if(isNaN(value))
      {

        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 'specialist': value })
        };

         fetch(Url + '/android/get_specialist.php', requestOptions)
        //fetch("http://localhost:2000/api/grahahermine/getspecialist/" + value)
        .then((res) => res.json())
        .then((json) => {setSpecialist(json);})
      }
    }

    const handleDay = (event)=>
    {
      const {name, value} = event.target; // dynamic get field name and value
      setInput({...input, [name]: value}); // dynamic set field name and value
    }


    const handleClick_Search = (event)=>
    {
      if(input.txtName !== null)
      {
        let param1 = input.txtName.length == 0 ? "null" : input.txtName;
        let param2 = input.txtSpecialist.length == 0 ? "null" : input.txtSpecialist;
        let param3 = input.optDay.length == 0 ? "null" : input.optDay;

        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 'name': param1, 'specialist' : param2, 'day' : param3 })
        };

        fetch(Url + '/android/get_doctorlist.php', requestOptions)
        //fetch("http://localhost:2000/api/grahahermine/getdoctorlist/" + param1 + "/" + param2 + "/" + param3)
        .then((res) => res.json())
        .then((json) => {passToParent(json);})

        // .then((json) => {setDoctorList(json);})


      }

    }

    React.useEffect(() => {
      // fetch("http://localhost:2000/api/grahahermine")
      //     .then((res) => res.json())
      //     .then((json)=> { setVal(json); } );
    }, []);
    // const handleChangeSpecialist = (event, values) => {
    //   if(event.target.value.length !== 0)
    //   {
    //     if(event.target.value !== 0){
    //       fetch("http://localhost:2000/api/tutorials/" + event.target.value)
    //       .then((res) => res.json())
    //       .then((json)=> { setVal(json); } );
    //     }
    //   }
    // }

    return(
    <React.Fragment>

      <div className="tabContent">
      <h3>Cari Jadwal Praktek Dokter - Rumah Sakit Graha Hermine</h3>

      <div className="box">
      <div className="leftBox">
      </div>
        <table>
          <tbody>
          <tr><td colSpan= "3">
            <AutoComplete freeSolo
                          options={name.map((array)=> array.name)}
                          onInputChange={handleAutoName}
                          renderInput={(params) =>
                                      <TextField {...params} name="txtName" value={input.txtName} label="Nama Dokter" onChange={handleName} />}
                          size="small"
                          />

          </td></tr>
          <tr><td colSpan= "3"><div className="atau">Atau</div></td></tr>
          <tr>
            <td style={{width:"30%"}}>
              <AutoComplete freeSolo onInputChange={handleAutoSpecialist}
                          options={specialist.map((array) => array.specialist)}        //{[...new Set(name.map((a)=> a.specialist))]}
                          renderInput={(params) =>
                                      <TextField {...params} name="txtSpecialist" value={input.txtSpecialist} label="Specialis" onChange={handleSpecialist} />}
                          size="small"
                          />
            </td>
            <td style={{width:"30%"}}>
                <TextField select onChange={handleDay} value={input.optDay} name="optDay" id='Hari' label="Hari" variant="outlined" size="small" className="textfield">
                  {
                    days.map((x)=>(
                      <MenuItem key={x.value} value={x.value}> {x.label} </MenuItem>
                    ))
                  }
                </TextField>
            </td>
            <td style={{width:"30%"}}><Button  id='Cari' label="Cari" onClick={handleClick_Search} variant="contained"  size="normal" startIcon={<SearchIcon/>} className="button">Cari</Button></td>
          </tr>
            </tbody>
          </table>
        </div>
      </div>


    </React.Fragment>

  );
}



const DoctorResult = (props)=> {
  const data = props.data;

  if(data.length !== 0){
    return(
      <div className="DoctorResult">
        <div className="CautionBox">
            <InfoTwoToneIcon/> &nbsp; Anda dapat membuat janji temu via website di hari yang sama paling lambat 4 jam sebelum jadwal yang anda pilih dan menunggu konfirmasi lebih lanjut
        </div>

        <h3>{data.length} Dokter ditemukan</h3>

        {data.map((row, index)=>{

                  return <DoctorCard key={index} row= {row}/>
          })}
      </div>
    );
  }else{
    return(   <div className="DoctorResult" style={{display: "none"}}>
      </div>
    );
  }
}

export const TabHowto = (props) =>
{
  let icon1 = require('../assets/step/choose_doctor_1.png');
  let icon2 = require('../assets/step/choose_datetime_2.png');
  let icon3 = require('../assets/step/verify_3.png');
  let icon4 = require('../assets/step/goto_hospital_4.png');


  return(
      <div className="tabContent">
          <h3>Tata Cara Membuat Janji</h3>
          <div className="BoxHowto">
              <div className="Top">
                  <div className="IconBox">
                      <p>1. Pilih Dokter</p>
                      <div className="IconStep" style={{backgroundImage : `url(${icon1})`}} ></div>
                  </div>
                        <ArrowForwardIcon fontSize="large" />
                  <div className="IconBox">
                      <p>2. Pilih Tanggal & Waktu</p>
                      <div className="IconStep" style={{backgroundImage : `url(${icon2})`}} ></div>
                  </div>
                        <ArrowForwardIcon fontSize="large"/>
                  <div className="IconBox">
                      <p>3. Masukkan Data Pasien</p>
                      <div className="IconStep" style={{backgroundImage : `url(${icon3})`}} ></div>
                  </div>
                        <ArrowForwardIcon fontSize="large"/>
                  <div className="IconBox">
                      <p>4. Datang ke Rumah Sakit</p>
                      <div className="IconStep" style={{backgroundImage : `url(${icon4})`}} ></div>
                  </div>
              </div>

              <div className="Bottom">
                  <Button id='Start'
                          className='StartButton'
                          label="Start"
                          variant="contained"
                          size="normal"
                          startIcon={<SearchIcon/>}
                          onClick={props.onClick}
                          value="1"
                          >pilih dokter</Button>

                  <Button >
                          pelajari lebih lanjut tata cara membuat janji
                  </Button>

              </div>

          </div>
      </div>
    );
}

const TabJadwalJanji = (props) =>
  {
    let ConditionalComponent ;
    console.log('props.id',props.id)
    if(props.id != null){
        ConditionalComponent = <div className="BoxAppoinment">
                               <PatientBookingHistoryList login={props.login} id={props.id} phone={props.phone} handleDetail={props.handleDetail}/>
                               </div>;
    }else{
        ConditionalComponent = <div className="BoxAppoinmentEmpty">
                               <TabLogin handleLoginTab_Click={props.handleLoginTab_Click}/>
                               </div>;
    }

    return(
      <div className="tabContent">
          <h3>Jadwal janji anda : </h3>

              {ConditionalComponent}

      </div>
    );

  }


const PatientBookingHistoryList = (props) => {
  const [data, setData]= useState(null);

  console.log('props',props)
  useEffect(async() => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'id': props.id, 'phone' : props.phone })
    };

    if(props.id){
      const response = await fetch(Url + '/android/get_bookingdata.php', requestOptions);
      const json = await response.json();
      setData( json );
    }

    console.log('mounted')

  },[])

  if(data === null) return <div></div>

  return(
    <div className="PatientHisBookingBox">
          <div className="Top">
          </div>
          <div className={"Bottom-Horizontal"}>
            <DataGridInformation data={data}/>
            <Button
             variant="contained"
             onClick={props.handleDetail}>Detail</Button>
          </div>
      </div>
  );
}

const TabLogin = (props) => {
    let iconEmpty = require('../assets/step/empty.png');
    return(
        <React.Fragment>
            <div className="IconEmpty" style={{backgroundImage : `url(${iconEmpty})`}} ></div>
            <p><InfoTwoToneIcon/> &nbsp; Anda harus melakukan login untuk melihat janji yang telah di buat. </p>
            <Button
              variant="contained"
              onClick={props.handleLoginTab_Click}>
                    masuk
            </Button>

        </React.Fragment>
    );
}



const DataGridInformation = (props)=>{

  const Columns = [
    { field: "bookingId", headerName: "Nomor", flex: 0.05, },
    { field: "bookDate", headerName: "Tanggal", flex: 0.1, },
    { field: "bookTime", headerName: "Waktu", flex: 0.09 },
    { field: "id", headerName: "NIK", flex: 0.08 },
    { field: "patientName", headerName: "Nama Pasien", flex: 0.15 },
    { field: "phone", headerName: "Hp", flex: 0.1 },
    { field: "name", headerName: "Dokter", flex: 0.1},
    // { field: "specialist", headerName: "Spesialist", flex: 0.1 },
  //   { field: "photo", headerName: "Foto", flex: 0.1 },
  ];

  return(
          <Box sx={{ height: "30vh", width: "100%" }}>
              <DataGrid
              getRowId={row => row.bookingId}
              rows={props.data}
              columns={Columns}
              pageSize={20}
              rowsPerPageOptions={[20]}
              density={"compact"}
              />
          </Box>
  );
}
