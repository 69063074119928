import React, {useState, useEffect, useCallback} from 'react';
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import { Url } from '../global';

//COMPONENT
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PoliclinicViewer from "../page/PoliclinicViewer";

//STYLE & ICON
import './Policlinic.css';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';


export const Policlinic = (props)=>{
   const [currentIndex, setCurrentIndex] = React.useState(0);
   const [openService, setOpenService] = useState(false);

   const [data, setData] = useState([]);
   const [serviceId, setServiceId] = useState(1);
   const [serviceContent, setServiceContent] = useState([]);
   const [servicePhoto, setServicePhoto] = useState([]);
   const [partnerHover, setPartnerHover] = useState([]);
   
   
   useEffect(async() => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'section': 'Poliklinik' })
      };

    const response = await fetch(Url + '/android/get_service.php', requestOptions);
    const json = await response.json();
    setData( json );
    setPartnerHover(()=> data.map(() => false));

   },[])

   useEffect(async() => {
    // alert('change service id ' + serviceId);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'serviceId': serviceId })
    };

    const response1 = await fetch(Url + '/android/get_service_content.php', requestOptions);
    const json1 = await response1.json();
    setServiceContent( json1[0] );

    const response2 = await fetch(Url + '/android/get_service_photo.php', requestOptions);
    const json2 = await response2.json();
    setServicePhoto(json2);

    // alert(JSON.stringify(serviceContent));

},[serviceId])



   const width = 520; //banner width
   let position = 0;
   position -= width * currentIndex;

   let itemCount = data.length;


const handleOver = (event) => {
  const newVisibilities = [...partnerHover];
  newVisibilities[event.currentTarget.id] = true;
  setPartnerHover(newVisibilities);
}

const handleOut = (event) => {
  const newVisibilities = [...partnerHover]; // store hover state to temp variable
  newVisibilities[event.currentTarget.id] = false; // set target id status in temp variablee
  setPartnerHover(newVisibilities); // set to videoHover state 
}

const PartnerViewer_Open = (event)=>{
    setServiceId(event.currentTarget.id);
    setOpenService(true);
}

const PartnerViewer_Close = ()=>{
    setOpenService(false);
    setServicePhoto([]);
    setServiceContent([]);

}

// alert(JSON.stringify(partnerHover));

    return(
            <div className="PoliclinicContainer">
            {
                // data.map(
                //     (item,index)=>{
                //         if(item.Id == partnerId){
                //         return(
                //             <PartnerViewer 
                //                 open={openPartner}
                //                 PartnerViewer_Close={PartnerViewer_Close}
                //                 Photo={item.Thumbnail}
                //                 Header={item.Header}
                //                 Content={item.Content}
                //                 />
                //             )
                //         }
                //     }

                // )

                <PoliclinicViewer open={openService} handleInformation_Close={PartnerViewer_Close}
                articleId= {serviceId} articleContent= {serviceContent} articlePhoto = {servicePhoto}/>

            }
            <h3>Layanan :</h3>

                <div className="ControlContainer">
                    <div className="PoliclinicBox">
                    {

                        data.map((item, index)=>{
                            return(
                                    <PoliclinicBox key={index} width = {width} index = {index} position = {position}
                                    partnerId = {item.Id}
                                    photo={item.Thumbnail} 
                                    Header = {item.Header}
                                    PartnerViewer_Open = {PartnerViewer_Open} 
                                    handleOver={handleOver}
                                    handleOut={handleOut}
                                    hover={partnerHover[item.Id]}
                                    />
                            );
                        })
                    }
                    </div>
                </div>
            </div>

        );
        // {videoHover[item.Id] ? <PlayWatermarkActive className="PlayWatermark" onClick={InformationViewer_Open}/> : <PlayWatermark className="PlayWatermark" onClick={InformationViewer_Open}/>}
}

const PoliclinicBox = (props) =>
{
    // const path = props.hover ? "assets/partner/" + props.photo : "assets/policlinic/" + props.photo ;
    const path = "assets/policlinic/final/" + props.photo ;
    const image = require("../" + path);
    // backgroundImage: `url(${ image })`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "80%"
    let classname = props.hover ? "PoliclinicCard Active" : "PoliclinicCard" ;
    return (
                <Card className={classname} id={props.partnerId} >
                    <div id= {props.partnerId} className="Media" style={{ backgroundImage: `url(${ image })`}}
                        onClick={props.PartnerViewer_Open}
                        onMouseOver={props.handleOver}
                        onMouseOut={props.handleOut}
                        >
                    </div>
                    <CardContent className="Content" id={props.partnerId} onClick={props.PartnerViewer_Open}>
                    <h3>{props.Header}</h3>
                        {/* 
                        <p className="Datetime">{props.CreateDate} </p>
                        <p>{props.Description}</p> */}
                    </CardContent>
                </Card>
    )


}


export default Policlinic;

