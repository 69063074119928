import React, {useState, useEffect, useCallback} from 'react';
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import { Url } from '../global';

//COMPONENT
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InformationViewer from "../page/InformationViewer";

//STYLE & ICON
import './Information.css';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';


export const Information = (props)=>{
   const [currentIndex, setCurrentIndex] = React.useState(0);
   const [openInformation, setOpenInformation] = useState(false);

   const [data, setData] = useState([]);
   const [articleId, setArticleId] = useState(1);
   const [articleContent, setArticleContent] = useState([]);
   const [articlePhoto, setArticlePhoto] = useState([]);


   useEffect(async() => {
    // const response = await fetch('http://rsgrahahermine.id/android/get_article.php');
    const response = await fetch(Url + '/android/get_article.php');


    const json = await response.json();
    // alert(json);
     setData( json );
    //  alert("content")

   },[])

   useEffect(async() => {
    // alert(articleId);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 'articleId': articleId })
    };


    // const response1 = await fetch('http://rsgrahahermine.id/android/get_article_content.php', requestOptions);
    const response1 = await fetch(Url + '/android/get_article_content.php', requestOptions);
    const json1 = await response1.json();
    setArticleContent( json1[0] );

    // const response2 = await fetch('http://rsgrahahermine.id/android/get_article_photo.php', requestOptions);
    const response2 = await fetch(Url + '/android/get_article_photo.php', requestOptions);
    const json2 = await response2.json();
    setArticlePhoto(json2);

   },[articleId])



   const width = 520; //banner width
   let position = 0;
   position -= width * currentIndex;

   let itemCount = data.length;

   const HandleNext_Click = ()=>{
       let index = currentIndex + 1
       // if current index is more than item count then go back to first
       if(index > (itemCount - 2)){
            index = 0;
       }

       setCurrentIndex(index);
   }

   const HandlePrev_Click = ()=> {
       let index = currentIndex - 1
       // if current index is more lest than first index then go to last item
       if(index < 0 ){
            index = itemCount - 2;
       }

       setCurrentIndex(index);
   }


    const InformationViewer_Open = (event)=>{
        setArticleId(event.currentTarget.id);
        setOpenInformation(true);
    }

    const InformationViewer_Close = ()=>{
        setOpenInformation(false);
    }

    return(
            <div className="InformationContainer">
            <InformationViewer open={openInformation} handleInformation_Close={InformationViewer_Close}
                articleId= {articleId} articleContent= {articleContent} articlePhoto = {articlePhoto}/>

            <h3>INFORMASI KESEHATAN :</h3>
                <div className="ControlContainer">
                    <IconButton onClick={HandlePrev_Click} style={{borderRadius:"0"}}>
                        <ArrowBackIosNewIcon />
                    </IconButton>
                    <div className="InformationBox">
                    {
                        data.map((item, index)=>{
                            return(
                                    <InformationBox key={index} width = {width} index = {index} position = {position}

                                    articleId = {item.Id}
                                    photo={item.Thumbnail}
                                    Header = {item.Header}
                                    CreateDate = {item.CreateDate}
                                    Description={item.Description}
                                    InformationViewer_Open = {InformationViewer_Open} />
                            );
                        })
                    }
                    </div>

                    <IconButton onClick={HandleNext_Click} style={{borderRadius:"0"}}>
                        <ArrowBackIosNewIcon style={{ transform: "rotate(180deg)"}}/>
                    </IconButton>
                </div>

            </div>

        );

}

const InformationBox = (props) =>
{
    const path = Url + "/assets/article/" + props.photo;
    // const image = require("../" + path); // <-- this is for build from source
    // `url(${ image    })`                 // <--use in return block
    // alert(props.id)
    return (
                <Card className="Card" id={props.articleId} style ={{ left:(props.width * props.index) + props.position }} >
                    <div id= {props.articleId} className="CardThumbnail" style={{backgroundImage: `url(${ path })`}}
                        onClick={props.InformationViewer_Open}>
                    </div>
                    <CardContent className="Content" id={props.articleId} onClick={props.InformationViewer_Open}>
                        <h3>{props.Header}</h3>
                        <p className="Datetime">{props.CreateDate} </p>
                        <p>{props.Description}</p>
                    </CardContent>
                    <CardActions>
                        {/* <IconButton aria-label="add to favorites">
                        <FavoriteIcon />
                        </IconButton>
                        <IconButton aria-label="share">
                        <ShareIcon />
                        </IconButton> */}
                    </CardActions>
                </Card>
    )


}


export default Information;

