import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { Url } from '../global';

import { useFormik } from 'formik';
import * as Yup from 'yup';


import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

//FORM INPUT
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';

//ICON
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import VpnKeyTwoToneIcon from '@mui/icons-material/VpnKeyTwoTone';
import ContactMailTwoToneIcon from '@mui/icons-material/ContactMailTwoTone';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import Person from '@mui/icons-material/Person';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import GoogleIcon from '@mui/icons-material/Google';
import logo from '../assets/logo.png';

//google api
import { GoogleLogin , useGoogleLogin} from '@react-oauth/google';

const LoginWithGoogle = (props) => {
    const [nik, setNik ]= useState('');
    const [handphone, setHandphone ]= useState('');
    const [googleData, setGoogleData ]= useState();
    const [input, setInput] = useState({username:"", password:"", type: "", token: ""});
    const [user, setUser] = useState(null);

    console.log(user)
    useEffect(
       async () => {
            if (user) {
              const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'token': user.access_token })
              };
          
              const response = fetch(Url + '/android/loginWithGoogle.php', requestOptions);
              const json = response.json();
              if(json == false)
              {
                  alert("handphone / nik error");
              }
              else
              {
              }
    
                // const requestOptions = {
                //     method: 'GET',
                //     headers: { 'Content-Type': 'application/json' },
                //     Authorization: `Bearer ${user.access_token}`,
                //     Accept: 'application/json',
                // };
                
                // const response = await fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, requestOptions)
                // const json = await response.json()
            }
        },
        [user]
    );

    const UseGoogleLogin = useGoogleLogin({
        onSuccess: (response) => {setUser(response)},
        onError: (response) => {console.log(response)}
    })


    const handleLogin_Click = (values) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'username': values.id, 'password' : values.phone }),
            mode: 'cors',
            credentials: 'same-origin'
          };
  
        fetch(Url + '/android/panel/login.php', requestOptions)
        .then((response) => response.json())
        .then((respObj) => {
          const json = respObj
          if(json == false)
          {
              alert("handphone / nik error");
          }
          else
          {
          }
        });
        
    }


    const YUP_SCHEMA = Yup.object({
        username: Yup.string()
        .required('Username is required'),
    
        password: Yup.string()
        .required('Password is required'),
    });

    const Formik = useFormik({
        enableReinitialize: true,
        initialValues: input,
        validationSchema: YUP_SCHEMA,
        onSubmit: handleLogin_Click,
    });
    
    const handleNIK_Change = (event)=>{
        setNik(event.target.value);
    } 

    const handleHandphone_Change = (event)=>{
        setHandphone(event.target.value);
    }
    
    const SuccessMessage = (response) => {setUser(response)}
    const ErrorMessage = (response) => {console.log(response)}


      return(
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Grid
            container 
            // columns = {{xs:11, sm:5, md:5, lg:3.5 }}   
            xs={11} sm={5} md={5} lg={3.5}
            rowSpacing={{xs:3, sm:3, md:2, lg:2}}
            sx={{
              textAlign: "center",
              padding: "1.5em",
              border: "1px solid lightGray",
              borderRadius: "5px",
              backgroundColor: "white",
            }}
          >
            <Grid item xs={12} md={12} lg={12}>
              <img src={logo} style={{ minWidth: "10%" ,maxWidth: "25%" }} />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Box style={{textAlign: 'center'}}>Selamat Datang, Silahkan Sign in.</Box>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <TextField
                name="username"
                label="Username"
                value={Formik.values.username}
                onChange={Formik.handleChange}
                onBlur={Formik.handleBlur}
                error={Formik.errors.username && Formik.touched.username ? true : false }
                helperText={
                  Formik.errors.username && Formik.touched.username
                    ? Formik.errors.username
                    : null
                }
                size="standard"
                className="textfield"
                style={{ width: "100%" }}
                InputProps={{startAdornment:(<InputAdornment position="start"> <Person/> </InputAdornment>)}}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="password"
                label="Password"
                value={Formik.values.password}
                onChange={Formik.handleChange}
                onBlur={Formik.handleBlur}
                error={Formik.errors.password && Formik.touched.password ? true : false }
                helperText={
                  Formik.errors.password && Formik.touched.password
                    ? Formik.errors.password
                    : null
                }
                size="standard"
                className="textfield"
                style={{ width: "100%" }}
                InputProps={{startAdornment:(<InputAdornment position="start"> <VpnKeyIcon/> </InputAdornment>)}}
                type="password"
              />
            </Grid>

            {/* <Grid>
                <GoogleLogin onSuccess={SuccessMessage} onError={ErrorMessage} />
            </Grid> */}

            <Grid item xs={12} sx={{ textAlign: "right"}}>
              <Button onClick={Formik.handleSubmit} variant="contained" style={{ textTransform: "none", backgroundColor: "var(--primary)" }}>
                Sign In
                {/* {loading && <Loading type="inline" />} Next */}
              </Button>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Box style={{textAlign: 'center', fontSize: '10pt'}}>~ Atau ~</Box>
            </Grid>

            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Button variant="contained" startIcon={<GoogleIcon/>} 
              onClick={() => UseGoogleLogin()}> Sign in dengan Google </Button>
            </Grid>

          </Grid>
        </Box>
      );
   
  }
  
  
  export default LoginWithGoogle;
  