import React from 'react';

const numbers = [1,2,3,4,5];
const doubled = numbers.map((number) => <li> {number * 2} </li>);

function ListandKey(){
    return doubled;
}

function ListItems(props){
    return <li>{props.value}</li>;    
}

function NumberList(props){
    const numbers = props.numbers;
    const listItems = numbers.map((x, index) =>
        <ListItems key={index}
                   value={x} />);

    return(
        <ul> { listItems } </ul>
    );
}

function Posting(props){
    return (
                        <div key = {props.id} id = {props.id}>
                            <h3>{props.title}</h3>  
                            <p>{props.content}</p>  
                        </div>
    );

}

function Blog(props){
    const sidebar = (
        <ul>
            {props.posts.map((post) =>
                <li key ={post.id}>
                    {post.title}
                </li>
            )}
        </ul>        
    );

    const content = props.posts.map((posting) =>
                        <Posting  
                            key = {posting.id}
                            id = {posting.id}
                            title = {posting.title}
                            content = {posting.content}
                         />
                    );

    return(
        <div>
            {sidebar}
            <hr />
            {content}

        </div>
    );
}



export default NumberList;
