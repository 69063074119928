import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

//FORM INPUT
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';


//MODAL 
import Modal from '@mui/material/Modal';

//ICON
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import CloseIcon from '@mui/icons-material/Close';


const AwardsViewer = (props) => {
    const [currentIndex, setCurrentIndex ]= useState(0);
    const [data, setData ]= useState();

    const navigate = useNavigate();

    const width = 600; //photo width
    // let position = 0;
    // position -= width * currentIndex;
    // let itemCount = props.articlePhoto.length;

    const path = "assets/awards/" + props.Photo;
    // const image = require("../" + path); // <-- this is for build from source


    return(
        <Modal
            open={props.open}
            onClose={props.handleAwards_Close}  
            aria-labelledby="Infomation Viewer"
            aria-describedby="Infomation Viewer"
            className="ModalAwardsStyle"
        >

            <div className="ModalAwardsContainer">
                <div className = "TitleBar">
                    <IconButton aria-label="close" style={{borderRadius:"0"}} onClick={props.handleAwards_Close}>
                        <CloseIcon />    
                    </IconButton>
                </div>

                <div className="ControlContainer">
                 
                        <div className='AwardsMediaContainer'
                            style={{ backgroundImage: `url(${ path })`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }}>
                        </div>
                </div>

                <div className="ModalContent">
                    <IconButton aria-label="like">
                        <FavoriteIcon />    
                    </IconButton>
                    
                    <IconButton aria-label="share">
                        <ShareIcon />
                    </IconButton>

                    <h3>{props.Header}</h3>
                    <p className="Datetime">{props.CreateDate}</p>
                    <p className="ContentParagraph">{props.Content}</p>
                </div>

                
                

            </div>
        </Modal>
    );
}
  
export default AwardsViewer;
  