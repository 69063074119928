import React, {useState, useEffect} from 'react';
import { useParams, useLocation, Link } from "react-router-dom";
import { Url } from '../global';

//COMPONENT
import DoctorCardMini from "./DoctorCardMini";
import Step from './Step'

//FORM INPUT
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

//DATE PICKER
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';

//RADIO BUTTON
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

//MODAL
import Modal from '@mui/material/Modal';

//CSS & ICON
import './Booking.css';
import Lock from '@mui/icons-material/EnhancedEncryptionTwoTone';

//ROUTER
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate
} from "react-router-dom";



let row = [];
let timerHandle;
const BookingPatientDt =(parentProps)=> {
    let param = useLocation().state;
    console.log('BookingPatientDt', param)
    const [doctor, setDoctor] = useState([])
    const [patientData, setPatientData] = React.useState({"NIK" : "", "BPJS" : "", "Name" : "", "DOB" : new Date(), "Sex" : "", "Suku" : "", "Religion" : "",  "Email" : "",  "Phone" : "", "Faskes" : "", "SKDP" : ""});
    const [error, setError] = React.useState({"NIK" : false, "BPJS" : false, "Name" : false, "DOB" : false, "Sex" : false, "Suku" : false, "Religion" : false,  "Email" : false,  "Phone" : false, "Faskes" : false, "SKDP" : false});
    const [intervalId, setIntervalId] = React.useState();
    const [dateKey, setDateKey] = React.useState(); // Temporary Unused

    const handlePatientDt_Change = (event)=>{
        setPatientData(prevData => ( {...prevData, [event.target.name] : event.target.value} ))
        setError(prevData => ( {...prevData, [event.target.name] : event.target.value.length == 0 ? true : false} ))
    }

    const handlePatientDOB_Change = (event)=>{
        setPatientData(prevData => ( {...prevData, "DOB" : event} ))
    }

    const handlePatientDtValidation = ()=>{
        var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        let errMsg = "";

        if(patientData.NIK == "")
        {
        errMsg += "* NIK harus di isi \n"
        }

        if(patientData.Name == "")
        {
        errMsg += "* Nama harus di isi \n"
        }

        if(patientData.DOB == null)
        {
        errMsg += "* Tanggal lahir harus di isi \n"
        }

        if(patientData.Sex == "")
        {
        errMsg += "* Jenis Kelamin harus di pilih \n"
        }

        if(patientData.Phone == "")
        {
        errMsg += "* Nomor Telepon harus di pilih \n"
        }

        if(!emailPattern.test(patientData.Email))
        {
        errMsg += "* email tidak valid \n";
        }

        if(errMsg){
          alert(errMsg);
          return false; // if errmsg got value then return false
        }

        return true; //if no error return true
    }

    const handleProcess_Click = () => {
      if(handlePatientDtValidation()){
            // setState('MenuVerification');
            setOpen(true);
          }
    }

    //MODAL STATE AND HANDLE
    const [open, setOpen] = React.useState(false);
    const [verificationCode, setVerificationCode] = React.useState("");
    const [second, setSecond] = React.useState(0);
    const [verificationResponse, setVerificationResponse] = React.useState();
    const [state, setState] = useState({'send' : false})
    const navigate = useNavigate();


    useEffect(() => {
      //alert(second)
      if(second <= 0){ // DO IF SECOND COUNT IS 0
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 'id': param.id })
          };

          //fetch('http://rsgrahahermine.id/android/get_doctorbyid.php', requestOptions)
          fetch(Url + '/android/get_doctorbyid.php', requestOptions)

          //fetch(`http://localhost:2000/api/grahahermine/getdoctor/${param.id}`)
              .then(response => response.json())
              .then(data => setDoctor(data))

          return () => clearInterval(intervalId);

      }
    }
    ,[second])

    useEffect(async () => {
      if(verificationCode.length == 6){
        const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ 'patientData' : patientData, 'verificationCode' : verificationCode, 'doctorName' : doctor[0].name, 'bookDate': param.selDateFormat, 'bookTime' : param.selTime })
            };
//        const response = await fetch('http://rsgrahahermine.id/android/check_verification.php', requestOptions);
        const response = await fetch(Url + '/android/check_verification_email.php', requestOptions);

        const json = await response.json();
       // alert(json[0].id);
        if(json == false)
        {
          setVerificationResponse(false);
          setVerificationCode("");
        }
        else
        {
          // setVerificationResponse(json);
          // navigate(`/bookingpatientdt/${row.id}&${selDateFormat}&${selTime}`);
          parentProps.handleSuccessVerify({id: json[0].id, phone: json[0].phone});
          navigate(`/patient`, {state:{id: json[0].id, phone: json[0].phone}});
        }

      }
    }
    ,[verificationCode])

    const handleSMS_Click = async (event)=>
    {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'phone' : patientData.Phone })
      };

      // const response = await fetch('http://rsgrahahermine.id/android/req_verification.php', requestOptions);
      const response = await fetch(Url + '/android/req_verification.php', requestOptions);

      const data = await response.json();
      setDateKey(data[0].Date);
      setSecond(60); // update the second

      timerHandle = setInterval( () => { setSecond((previous) => {return(previous - 1);} ) }
                               , 1000); // passing function will
      setIntervalId(timerHandle);
    }

    const handleEmail_Click = async (event)=>
    {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'email' : patientData.Email })
      };

      // const response = await fetch('http://rsgrahahermine.id/android/req_verification.php', requestOptions);
      const response = await fetch(Url + '/android/req_verification_email.php', requestOptions);
      const data = await response.json();
      console.log(data)
      setDateKey(data[0].Date);
      setState({...state, 'send' : true})
    }

    const handleVerification_Close = (event)=>{
      setVerificationCode("");
      setVerificationResponse();
      setOpen(false);
      // setSend(false);
      // clearInterval(timerHandle);
    }

    const handleVerification_Change = (event)=>{
      setVerificationCode(event.target.value);
    }



  //if(props.state=="MenuPatientdata" || props.state =="MenuVerification"){
    return(
      <div className="BookingContainer">
      <div className="BookingBox">
      <div className="Top">
        <h2>Buat Janji Temu dengan: </h2>
      </div>

      <div className={"Bottom-Vertical"}>
          <div className="Left">
              <Step state={"MenuPatientData"}/>

              {
                doctor.map((item, index)=>{
                  row = item;
                  return(  <DoctorCardMini key={index} selDoctor={item} selDate={param.selDateFormat} selTime={param.selTime}/> );
                })
              }
          </div>

          <div className="PatientDataContainer">
            <div style={{marginBottom: "1em", fontWeight: "bold"}}>Silahkan mengisi data pasien :</div>
            <div className="DataForm">
              <div className="Left">
                <TextField onChange={handlePatientDt_Change} error={error.NIK} name='NIK' required  label="NIK" size="small" className="textfield" InputLabelProps={{ shrink : true}} />
                <TextField onChange={handlePatientDt_Change}  name='BPJS' label="No. BPJS"  size="small" className="textfield" InputLabelProps={{ shrink : true}} />
                <TextField onChange={handlePatientDt_Change} error={error.Name} required name='Name' label="Nama"  size="small" className="textfield" InputLabelProps={{ shrink : true}} />

                <LocalizationProvider dateAdapter={AdapterDateFns} style={{border:"1px solid gray"}}>
                  <MobileDatePicker size="small"
                    label="Tanggal Lahir"
                    inputFormat="MM/dd/yyyy"
                    name='DOB'
                    value= {patientData.DOB}
                    onChange={handlePatientDOB_Change}
                    renderInput={(params) => <TextField name='DOB' size="small" className="textfield" InputLabelProps={{ shrink : true}} {...params} />}
                  />
                </LocalizationProvider>

                {/* <TextField onChange={props.handlePatientData} name='DOB' label="TTL"  size="small" className="textfield" InputLabelProps={{ shrink : true}} /> */}
                <div style={{margin: "8px", padding: "8px", width: "90%"}}>
                  <FormLabel style={{fontSize: "0.8em"}}>Jenis Kelamin</FormLabel>
                  <RadioGroup onChange={handlePatientDt_Change} name="Sex">
                    <FormControlLabel label="Laki-laki" value="Male" control={<Radio size="small"/>}/>
                    <FormControlLabel label="Perempuan" value="Female" control={<Radio size="small"/>}/>
                  </RadioGroup>
                </div>
              </div>
              <div className="Right">
                <TextField onChange={handlePatientDt_Change} name='Email' label="Email" size="small" className="textfield" InputLabelProps={{ shrink : true}} inputProps={{ pattern: "[a-z]{1,15}" }} />
                <TextField onChange={handlePatientDt_Change} name='Religion' label="Agama"  size="small" className="textfield" InputLabelProps={{ shrink : true}} />
                <TextField onChange={handlePatientDt_Change} name='Suku' label="Suku" size="small" className="textfield" InputLabelProps={{ shrink : true}} />
                <TextField onChange={handlePatientDt_Change} name='Phone' label="No. Telp"  size="small" className="textfield" InputLabelProps={{ shrink : true}} />
                <TextField onChange={handlePatientDt_Change} name='Faskes' label="Rujukan dari Faskes"  size="small" className="textfield" InputLabelProps={{ shrink : true}} />
                <TextField onChange={handlePatientDt_Change} name='SKDP' label="No. Surat Kontrol / No SKDP"  size="small" className="textfield" InputLabelProps={{ shrink : true}} />
              </div>
            </div>

            <div className="ButtonForm">
                <Button onClick={handleProcess_Click} style={{float: "right", width:"20%", height:"20%"}} variant="contained" >
                  Verifikasi
                  {/* <Link to={{pathname: `/bookingpatientdt/${row.id}`}}> PROSES </Link>  */}
                </Button>

            </div>
              {/* <ModalVerification patientData={patientData}   handleVerification_Change={handleVerification_Change} handleVerification_Close={handleVerification_Close}/> */}
              <ModalVerification patientData={patientData}
                                 second= {second}
                                 open={open}
                                 verificationCode = {verificationCode}
                                 verificationResponse = {verificationResponse}
                                 handleVerification_Change={handleVerification_Change}
                                 handleVerification_Close={handleVerification_Close} handleSMS_Click={handleSMS_Click} handleEmail_Click={handleEmail_Click}
                                 state = {state}
                                 />

          </div>

      </div>

      </div>
      </div>

    );

}


// MODAL
const ModalVerification = (parentProps) => {
  console.log(parentProps)
  const SendVerificationButton  = (props) =>{

    if(parentProps.second >= 1){
        return(
        <div>
          Kirim ulang kode verifikasi setelah {parentProps.second} detik
        </div>
       );
    }
    else
    {
      return(
        <React.Fragment>
          {/* <div>
              <Button onClick={parentProps.handleSMS_Click} style={{textTransform: "none"}}>Kirim Verifikasi melalui SMS</Button>
              </div> */}

          <div>
            <Button style={{textTransform: "none"}} onClick={parentProps.handleEmail_Click} >Kirim Verifikasi melalui Email</Button>
          </div>
        </React.Fragment>
        );
    }
  }


    return(
      <Modal
        open={parentProps.open}
        onClose={parentProps.handleVerification_Close}
        aria-labelledby="Verification-Menu"
        aria-describedby="Verification-Code"
      >

        <div className="VerificationModal">
          <div>
            <Lock fontSize="large" style={{color: "var(--primary)" }} /> &nbsp; <h3 style={{color: "var(--primary)" }}>Masukkan Kode Verifikasi</h3>
          </div>
          <div>
            <TextField name='VerificationCode' onChange={parentProps.handleVerification_Change} value={parentProps.verificationCode} disabled={parentProps.verificationCode.length == 6 ? true : false} required label="Verification Code" size="small" className="textfield" style={{marginTop:"1em"}} InputLabelProps={{ shrink : true}} />
          </div>

            { parentProps.verificationResponse == false ? <div style={{fontSize: "8pt", color: "red"}}> Maaf Kode Verifikasi yang anda masukkan tidak valid </div> : null}

            {
              parentProps.state.send == false
              ? <SendVerificationButton/>
              : <div>kode verifikasi sudah terkirim, silahkan cek email anda.</div>
            }

        </div>
      </Modal>
    );

}


export default BookingPatientDt;
