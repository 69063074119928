import {React, useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import MedicalServicesTwoToneIcon from '@mui/icons-material/MedicalServicesTwoTone';
import { Link } from "react-router-dom";
import DoctorViewer from "../page/DoctorViewer";
import { Url } from '../global';



const DoctorCard = (props)=>{
    const [openDoctorViewer, setOpenDoctorViewer] = useState(false);
    const [qualification, setQualification] = useState([]);

    const row = props.row;
    let photo;
    let DefaultPhoto;
  
    if(row.photo !== "-")
      //photo = require('../assets/doctor/' + row.photo)
      photo = '/assets/doctor/' + row.photo;
      
    else
      //photo = require('../assets/doctor/doctorDefault.png')
      photo = '/assets/doctor/doctorDefault.png';

    const OpenDoctorViewer = (event)=>{
      setOpenDoctorViewer(true);
    }

    const CloseDoctorViewer = ()=>{
      setOpenDoctorViewer(false);
    }



    useEffect(
      () => {
        if(openDoctorViewer)
        {
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'Id': row.id })
          };
      
          fetch(Url + '/android/get_doctor_qualification.php', requestOptions)
          .then((res) => res.json())
          .then((json)=> { setQualification(json); } );
        }
      }, [openDoctorViewer])



    return(
      
    <div className="doctorCard">
      <DoctorViewer open={openDoctorViewer} handleCloseDoctorViewer={CloseDoctorViewer} data={row} qualification={qualification}/>

      <div className="top">
        <div className="photo" style={{backgroundImage: `url(${ photo })`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
        </div>
        <div className="info">
          <h3>{row.name} 
            <Button id = {row.id} variant="contained" style={{textTransform: "none" , marginLeft: "1em", }} size="small" onClick={(e) => OpenDoctorViewer(e)}>Lihat Profile</Button> 
          </h3>
          <p> <MedicalServicesTwoToneIcon/>  &nbsp; {row.specialist} </p>
          <Button variant="contained" style={{textTransform: "none" , margin:"0 5 5 5", background: "var(--primary)"}} >
             {/* <Link to={`/booking/${row}`}> BUAT JANJI </Link>  */}
             <Link to={{pathname: `/booking/${row.id}`}}> BUAT JANJI </Link> 
  
          </Button>
        </div>
      </div>
  
      <div className="bottom">
        <div><div className="thead">Senin</div>  <div className="trow">{row.monday}</div></div>
        <div><div className="thead">Selasa</div> <div className="trow">{row.tuesday}</div></div>
        <div><div className="thead">Rabu</div>   <div className="trow">{row.wednesday}</div></div>
        <div><div className="thead">Kamis</div>  <div className="trow">{row.thursday}</div></div>
        <div><div className="thead">Jumat</div>  <div className="trow">{row.friday}</div></div>
        <div><div className="thead">Sabtu</div>  <div className="trow">{row.saturday}</div></div>
        <div><div className="thead">Minggu</div> <div className="trow">{row.sunday}</div></div>
      </div>
  
    </div>
    );
  }

export default DoctorCard;

  