import React from 'react';

function FancyBorder(props){
    return(
        <div className={'FancyBorder FancyBorder-'+ props.color}>
            {props.children}
        </div>
    );
}


function Dialog(props){
    return(
        <FancyBorder color={props.color}>
            <h1 className="Dialog-title">
                {props.title}
            </h1>
            <p className="Dialog-message">
                {props.message}
            </p>
            {props.children}
        </FancyBorder>
            
    );
}

function WelcomeDialog(props){
    return(
        <Dialog title="Welcome"
                message="Thank you for visiting our spacecraft!" 
                color={props.color}/>
    );
}

class SignUpDialog extends React.Component{
    constructor(props){
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSignUp = this.handleSignUp.bind(this);
        this.state = {login: ''};
    }

    handleChange(e){
        this.setState({login: e.target.value});
    }

    handleSignUp(){
        alert(`Welcome aboard, ${this.state.login} !`);
    }

    render(){
        return(
            <React.Fragment>
            <WelcomeDialog color="red"/ >
            <Dialog title="Mars Exploration Program" message="How should we refer to you?">
                <input type="text" onChange={this.handleChange}/>
                <button onClick={this.handleSignUp}>
                    Sign Me Up!
                </button>
            </Dialog>
            </React.Fragment>
        );
    }
}

function  SplitPane(props) {
    return(
        <div className="SplitPane">
            <div className="SplitPane-left">
                {props.left}
            </div>
            <div className="SplitPane-right">
                {props.right}
            </div>
        </div>
    );
}

function Contacts(){
    return('contact area');
}

function Chat(){
    return('chat area');
}


function App(){
    return(
        <SplitPane left={<Contacts/>} right={<Chat />} />
    );

}

export default SignUpDialog;
