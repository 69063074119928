//icon
import MedicalServicesTwoToneIcon from '@mui/icons-material/MedicalServicesTwoTone';
import ConfirmationNumberTwoToneIcon from '@mui/icons-material/ConfirmationNumberTwoTone';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import BadgeTwoToneIcon from '@mui/icons-material/BadgeTwoTone';
import LocalPhoneTwoToneIcon from '@mui/icons-material/LocalPhoneTwoTone';
import VerifiedUserTwoToneIcon from '@mui/icons-material/VerifiedUserTwoTone';

const PatientCard = (props)=>{
    // const row = props.selDoctor;
    // let photo;
    // let DefaultPhoto;
    // if(row.photo !== "-" && row)
    //   photo = require('../assets/doctor/' + row.photo)
    // else
    //   photo = require('../assets/doctor/doctorDefault.png')
    
   return(
    <div className="PatientCard">
       <div><ConfirmationNumberTwoToneIcon/> <p>{props.row.bookingId}</p> </div>
       <div><AccountCircleTwoToneIcon/> <p>{props.row.patientName}</p> </div>
       <div><BadgeTwoToneIcon/> <p>{props.row.id}</p> </div>
       <div><LocalPhoneTwoToneIcon/> <p>{props.row.phone}</p> </div>
       <div><VerifiedUserTwoToneIcon/> <p>Terverifikasi </p></div> 
    </div>
    );
  }

export default PatientCard;
