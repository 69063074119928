import React, {useState, useEffect, useCallback} from 'react';
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";

//COMPONENT


//STYLE & ICON
import './UnderConstruction.css';


export const Awards = (props)=>{

    return(
        <div className="UnderConstruction" >
        </div>
    );

   

}


export default Awards;

