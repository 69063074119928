import React, {useState, useEffect} from 'react'
import { useParams, useLocation} from "react-router-dom"

import { Url } from '../global'

//COMPONENT
import {DataGrid} from '@mui/x-data-grid'
import {Box} from '@mui/material'

import DoctorCard2 from './DoctorCard2'
import PatientCard2 from './PatientCard2'


export const PatientPage = (props)=>{
   let param = useLocation().state
   const [state, setState] = useState(null)
   let id = localStorage.getItem('id')
   let phone = localStorage.getItem('phone')

   useEffect(async() => {
        const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'id': param.id, 'phone' : param.phone })
        }

        const response = await fetch(Url + '/android/get_bookingdata.php', requestOptions)
        const json = await response.json()
        setState( {...state, data : json, selected_data : json[0]} )

   },[])


   const handleRowClick = (data) => {
        setState({...state, selected_data : data})
   }

// if(props.login == false) return(<React.Fragment> sorry, you are unauthorized! </React.Fragment> )
   if(state == null) return (<React.Fragment> sorry, you are unauthorized! </React.Fragment> )

   return(
                <div className="PatientHisContainer">
                    <div className="PatientBookingBox">
                        <div className="top">
                            <DoctorCard2 data={state.selected_data}/>
                            <PatientCard2 data={state.selected_data}/>
                        </div>
                            <DataGridInformation data={state.data} handleRowClick={handleRowClick}/>
                    </div>
                </div>
        )
}


const DataGridInformation = (props)=>{

    const Columns = [
        { field: "bookingId", headerName: "Nomor", flex: 0.05, },
        { field: "bookDate", headerName: "Tanggal", flex: 0.07, },
        { field: "bookTime", headerName: "Waktu", flex: 0.07 },
        { field: "id", headerName: "NIK", flex: 0.08 },
        { field: "patientName", headerName: "Nama Pasien", flex: 0.1 },
        { field: "phone", headerName: "Hp", flex: 0.1 },
        { field: "name", headerName: "Dokter", flex: 0.1},
        //   { field: "photo", headerName: "Foto", flex: 0.1 },
    ];

    return(
            <Box sx={{ height: "40vh", width: "100%" }}>
                <DataGrid
                getRowId={row => row.bookingId}
                rows={props.data}
                columns={Columns}
                onRowClick={(event) => props.handleRowClick(event.row)}
                pageSize={20}
                rowsPerPageOptions={[20]}
                density={"compact"}
                />
            </Box>
    );
  }


export default PatientPage

